import React from 'react'
import "./New.css"
import img2 from "../../Images/banks/img (1).png"
import img3 from "../../Images/banks/img (1).webp"
import img4 from "../../Images/banks/img (2).jpg"
import img5 from "../../Images/banks/img (3).svg"
import img6 from "../../Images/banks/img (4).png"
import img7 from "../../Images/banks/img (5).png"
import img8 from "../../Images/banks/img (6).png"
import img9 from "../../Images/banks/img (7).png"
import img10 from "../../Images/banks/img (8).png"
import img11 from "../../Images/banks/img (2).png"
import img12 from "../../Images/banks/img (2).webp"

const New = () => {
  
  const images = [
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img2,
    img3,
    img4,
    img5,
];

  return (
    <div className='new-sachin-container'>
       <div className="new-sachin">
          {images?.map((res, index) => (
            <div className="jkj">
            <div className="new-sec">
            <img src={res} alt='blubucks' />
            </div>
            </div>
          ))}
        </div>
    </div>
  )
}

export default New
