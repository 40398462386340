import React, { useEffect } from 'react';
import styled from 'styled-components';

const PolicyContainer = styled.div`
   padding: 20px;
//   max-width: 800px;
   margin: 5%;
  padding: 20px;
    background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
  font-size: clamp(20px, 4vw, 40px);
  font-weight: 700;
`;

const Subtitle = styled.h2`
 font-size: clamp(10px, 4vw, 25px);
  margin-bottom: 10px;
    color: red;
`;

const Paragraph = styled.p`
 font-size: 16px;
  margin: 10px 0;
`;

const Strong = styled.strong`
  font-weight: bold;
`;

const AdditionalGuidelinesList = styled.ul`
  margin-left: 20px;
  list-style-type: disc;
`;

const dataStoragePolicy = {
  policyTitle: "Data Storage Policy",
  introduction: "At Blubucks Asset Pvt Ltd, we recognize the critical importance of safeguarding the data entrusted to us by our customers and stakeholders. Our Data Storage Policy ensures that all institutional data is handled with the highest level of security, consistent with its sensitivity, applicable regulations, and industry best practices. This policy aims to guide the standards and procedures for storing, archiving, and disposing of data.",
  dataClassificationAndHandling: {
    confidentialData: "Includes personal and financial information, loan details, identification documents, and sensitive company information.",
    nonConfidentialData: "Data that is public or has a lower sensitivity.",
    guidelines: "Blubucks employees should refrain from creating duplicate or shadow copies of authoritative data unless necessary. Temporary copies should be secured and removed when no longer needed."
  },
  protectedDataStorage: {
    confidentialDataLocations: "Protected data will only be stored in approved secure locations or storage facilities.",
    electronicDataStandards: "Standards for storing electronic data containing sensitive information will be established and reviewed regularly.",
    hardcopyDataStandards: "Procedures for securely storing physical documents with sensitive information will be followed."
  },
  dataBackupAndOffsiteStorage: {
    regularBackups: "Data stored on our IT resources will be regularly backed up based on its classification.",
    offsiteStorage: "Backup data containing confidential information will be securely stored off-site in a timely manner, and all backup media will be encrypted.",
    dataRetentionReviews: "Data that is no longer required for daily operations but still needs to be retained will be archived. The necessity of data retention will be reviewed continuously."
  },
  dataRetention: {
    retentionProcedures: "Data Managers will ensure that retention procedures are followed, keeping data accessible according to evolving technology standards and regulatory requirements.",
    archivalCriteria: "Criteria for archiving data and procedures for archiving will be defined in consultation with management."
  },
  dataDisposal: {
    disposalOfUnnecessaryData: "Data no longer required will be securely destroyed in line with state and company policies.",
    archivedDataDisposal: "Archived data will be disposed of following compliance requirements and will be securely deleted from all systems."
  },
  additionalGuidelines: {
    paperRecords: "Printed documents must be stored securely and disposed of via shredding.",
    electronicSecurity: "Data stored electronically will be protected from unauthorized access and cyber threats.",
    passwordProtection: "Access to data will be controlled by secure passwords that are periodically updated.",
    removableMedia: "Storage devices will be securely stored when not in use.",
    designatedStorageLocations: "Data should only be stored on approved drives and servers, located in secure environments.",
    regularTesting: "Data backup procedures will be tested regularly to ensure reliability."
  },
  securityAssurance: {
    description: "Security Assurance will regularly review compliance with data management policies and assess security measures for consistency with industry best practices."
  },
  contact: {
    description: "For any questions or concerns regarding data storage, contact support@blubucks.com."
  }
};
const Section = styled.div`
  margin-bottom: 20px;
    margin: 20px 0;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;
const DataStoragePolicy = () => {
  useEffect(()=>{
    scrollToTop()
  },[])
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'instant' });
    };
  return (
    <PolicyContainer>
      <Title>{dataStoragePolicy.policyTitle}</Title>
      <Paragraph>{dataStoragePolicy.introduction}</Paragraph>
      <Section>
      <Subtitle>Data Classification and Handling</Subtitle>
      <Paragraph><Strong>Confidential Data:</Strong> {dataStoragePolicy.dataClassificationAndHandling.confidentialData}</Paragraph>
      <Paragraph><Strong>Non-Confidential Data:</Strong> {dataStoragePolicy.dataClassificationAndHandling.nonConfidentialData}</Paragraph>
      <Paragraph><Strong>Guidelines:</Strong> {dataStoragePolicy.dataClassificationAndHandling.guidelines}</Paragraph>
      </Section>
      <Section>
      <Subtitle>Protected Data Storage</Subtitle>
      <Paragraph>{dataStoragePolicy.protectedDataStorage.confidentialDataLocations}</Paragraph>
      <Paragraph>{dataStoragePolicy.protectedDataStorage.electronicDataStandards}</Paragraph>
      <Paragraph>{dataStoragePolicy.protectedDataStorage.hardcopyDataStandards}</Paragraph>
      </Section>
      <Section>
      <Subtitle>Data Backup and Off-site Storage</Subtitle>
      <Paragraph>{dataStoragePolicy.dataBackupAndOffsiteStorage.regularBackups}</Paragraph>
      <Paragraph>{dataStoragePolicy.dataBackupAndOffsiteStorage.offsiteStorage}</Paragraph>
      <Paragraph>{dataStoragePolicy.dataBackupAndOffsiteStorage.dataRetentionReviews}</Paragraph>
      </Section>
      <Section>
      <Subtitle>Data Retention</Subtitle>
      <Paragraph>{dataStoragePolicy.dataRetention.retentionProcedures}</Paragraph>
      <Paragraph>{dataStoragePolicy.dataRetention.archivalCriteria}</Paragraph>
      </Section>
      <Section>
      <Subtitle>Data Disposal</Subtitle>
      <Paragraph>{dataStoragePolicy.dataDisposal.disposalOfUnnecessaryData}</Paragraph>
      <Paragraph>{dataStoragePolicy.dataDisposal.archivedDataDisposal}</Paragraph>
      </Section>
      <Section>
      <Subtitle>Additional Guidelines</Subtitle>
      <AdditionalGuidelinesList>
        <li>{dataStoragePolicy.additionalGuidelines.paperRecords}</li>
        <li>{dataStoragePolicy.additionalGuidelines.electronicSecurity}</li>
        <li>{dataStoragePolicy.additionalGuidelines.passwordProtection}</li>
        <li>{dataStoragePolicy.additionalGuidelines.removableMedia}</li>
        <li>{dataStoragePolicy.additionalGuidelines.designatedStorageLocations}</li>
        <li>{dataStoragePolicy.additionalGuidelines.regularTesting}</li>
      </AdditionalGuidelinesList>
      </Section>
      <Section>
      <Subtitle>Security Assurance</Subtitle>
      <Paragraph>{dataStoragePolicy.securityAssurance.description}</Paragraph>
      </Section>
      <Section>
      <Subtitle>Contact</Subtitle>
      <Paragraph>{dataStoragePolicy.contact.description}</Paragraph>
      </Section>
    
    </PolicyContainer>
  );
};

export default DataStoragePolicy;
