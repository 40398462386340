import React from 'react';

const PersonalLoanDsaAbout = () => {
  return (
    <div className="max-w-[1260px] mx-auto p-4">
      <h1 className="text-4xl font-semibold  mb-4">What is a Personal Loan DSA?</h1>
      <p className="text-lg mb-4">
        A Personal Loan Direct Selling Agent (DSA) is a person or company authorized by financial institutions to help borrowers navigate the application process. Loan DSAs serve as go-betweens connecting loan seekers with lending institutions by identifying potential customers, helping them gather the necessary documentation, and then forwarding their loan applications to the financial institutions after completion. Consequently, they receive payments for each successful loan referral they make. Thus, becoming a Personal Loan DSA Partner is a great opportunity.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Who can Become a Personal Loan DSA with Ruloans?</h2>
      <p className="text-lg mb-4">
        Enhance your professional status. Fire up your entrepreneurial instincts and drive into victory! Any person with interpersonal skills and a passion for success can be a part of Team Ruloans.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-6 gap-4 mb-8">
        <div className="flex flex-col items-center justify-center p-4 bg-gray-100 rounded-lg shadow-md">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14c.548 0 1 .452 1 1s-.452 1-1 1-1-.452-1-1 .452-1 1-1z" />
          </svg>
          <h3 className="text-xl font-medium text-gray-900 mt-2">Loan Agent</h3>
        </div>
        <div className="flex flex-col items-center justify-center p-4 bg-gray-100 rounded-lg shadow-md">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h3 className="text-xl font-medium text-gray-900 mt-2">Ex-Banker</h3>
        </div>
        <div className="flex flex-col items-center justify-center p-4 bg-gray-100 rounded-lg shadow-md">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h3 className="text-xl font-medium text-gray-900 mt-2">Financial Analyst</h3>
        </div>
        <div className="flex flex-col items-center justify-center p-4 bg-gray-100 rounded-lg shadow-md">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h3 className="text-xl font-medium text-gray-900 mt-2">Mutual Fund Agent</h3>
        </div>
        <div className="flex flex-col items-center justify-center p-4 bg-gray-100 rounded-lg shadow-md">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h3 className="text-xl font-medium text-gray-900 mt-2">Chartered Accountant</h3>
        </div>
        <div className="flex flex-col items-center justify-center p-4 bg-gray-100 rounded-lg shadow-md">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h3 className="text-xl font-medium text-gray-900 mt-2">Builder</h3>
        </div>
      </div>

      <h2 className="text-2xl font-semibold  mb-4">About Loan Agent Partner</h2>
      <p className="text-lg mb-4">
        You're a personal loan agent knowledgeable about all types of loans offered by the financial institutions you represent and can advise people on the best options. Get your BluBucksLoan Agent Partner advantage now and advise people on how to finance their dreams!
      </p>

      <h2 className="text-2xl font-semibold  mb-4">Help Finance Your Clients' Futures Now</h2>
      <ul className="list-disc ml-6 mb-4">
        <li>Personal Loans</li>
        <li>Home Loans</li>
        <li>Business Loans</li>
        <li>Auto Loans and More</li>
      </ul>

      <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        Become a Partner
      </button>
    </div>
  );
};

export default PersonalLoanDsaAbout;