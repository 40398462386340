import React from 'react'

const Document = ({loanType}) => {
    const loans = {
        personal_loan_salaried: {
          minimum_age: 24,
          identity_proof: ["Photograph", "Pan card", "Aadhar card"],
          proof_of_residence: ["Aadhar Card", "Utility Bill", "Rent Agreement"],
          income_proof: [
            "3 months latest salary slip",
            "2 years Form 16",
            "1 Year Bank Statement of all accounts",
          ],
          loan_proof: ["All Loan sanction Letter"],
        },
        home_loan_salaried: {
          minimum_age: 24,
          identity_proof: ["Photograph", "Pan card", "Aadhar card"],
          proof_of_residence: ["Aadhar Card", "Utility Bill", "Rent Agreement"],
          income_proof: [
            "3 months latest salary slip",
            "2 years Form 16",
            "1 Year Bank Statement of all accounts",
          ],
          loan_proof: ["All Loan sanction Letter"],
          property_documents: ["Property Papers"],
        },
        loan_against_property_salaried: {
          age_range: "24-65",
          identity_proof: ["Photograph", "Pan card", "Aadhar card"],
          proof_of_residence: ["Aadhar Card", "Utility Bill", "Rent Agreement"],
          income_proof: [
            "2 years Form 16",
            "1 Year Bank Statement of all accounts",
          ],
          loan_proof: ["All Loan sanction Letter"],
          property_documents: ["Property Papers"],
        },
        home_loan_self_employed: {
          identity_proof: ["Photograph", "Pan card", "Aadhar card"],
          proof_of_residence: ["Aadhar Card", "Utility Bill", "Rent Agreement"],
          income_proof: [
            "3 years ITR with financials",
            "1 Year Bank Statement of all accounts",
            "GST Certificate",
            "1 Year GST3B",
          ],
          loan_proof: ["All Loan sanction Letter", "SOA"],
          property_documents: ["Property Papers"],
          additional_documents: ["MSME certificate"],
        },
        loan_against_property_self_employed: {
          identity_proof: ["Photograph", "Pan card", "Aadhar card"],
          proof_of_residence: ["Aadhar Card", "Utility Bill", "Rent Agreement"],
          income_proof: [
            "3 years ITR with financials",
            "1 Year Bank Statement of all accounts",
            "GST Certificate",
            "1 Year GST3B",
          ],
          loan_proof: ["All Loan sanction Letter", "SOA"],
          property_documents: ["Property Papers"],
          additional_documents: ["MSME certificate"],
        },
        business_loan: {
          identity_proof: ["Photograph", "Pan card", "Aadhar card"],
          proof_of_residence: ["Aadhar Card", "Utility Bill", "Rent Agreement"],
          income_proof: [
            "3 years ITR with financials",
            "1 Year Bank Statement of all accounts",
            "GST Certificate",
            "1 Year GST3B",
          ],
          loan_proof: ["All Loan sanction Letter", "SOA"],
          property_documents: ["Property Papers"],
          additional_documents: ["MSME certificate"],
        },
        "Working_Capital/Overdraft_Limit": {
    
        identity_proof: [
          "Photograph",
          "PAN Card",
          "Aadhar Card"
        ],
        proof_of_residence: [
          "Aadhar Card",
          "Utility Bill",
          "Rent Agreement"
        ],
        income_proof: [
          "3 years ITR with financials",
          "1 year bank statement of all accounts",
          "GST Certificate",
          "1 year GST 3B"
        ],
        loan_proof: [
          "All loan sanction letters",
          "Statement of Accounts (SOA)"
        ],
        property_documents: [
          "Property papers"
        ]
    
    }
      };
      const loanDetails = loans[loanType];
console.log(loanDetails)
      if (!loanDetails) {
        return <div>Invalid loan type</div>;
      }
    
      return (
        <div className="max-w-[1260px] mx-auto p-4">
          <h2 className="text-2xl font-bold mb-4">
            Documents Required to Apply for {loanType.replace(/_/g, ' ')}
          </h2>
          <div className="border rounded-md p-4">
            <ul className="flex flex-col gap-2 text-lg">
              {loanDetails.identity_proof && (
                <li className="mb-2">
                  <span className="font-bold">Identity Proof</span> -{' '}
                  {loanDetails.identity_proof.join(', ')}
                </li>
              )}
              {loanDetails.proof_of_residence && (
                <li className="mb-2">
                  <span className="font-bold">
                    Proof of Residence or Address Proof
                  </span>
                  - {loanDetails.proof_of_residence.join(' / ')}
                </li>
              )}
              {loanDetails.income_proof && (
                <li className="mb-2">
                  <span className="font-bold">Income Proof</span> -{' '}
                  {loanDetails.income_proof.join(' + ')}
                </li>
              )}
              {loanDetails.loan_proof && (
                <li className="mb-2">
                  <span className="font-bold">Loan Proof</span> -{' '}
                  {loanDetails.loan_proof.join(', ')}
                </li>
              )}
              {loanDetails.minimum_age && (
                <li className="mb-2">
                  <span className="font-bold">Minimum Age</span> -{' '}
                  {loanDetails.minimum_age}
                </li>
              )}
              {loanDetails.property_documents && (
                <li className="mb-2">
                  <span className="font-bold">Property Documents</span> -{' '}
                  {loanDetails.property_documents.join(', ')}
                </li>
              )}
              {loanDetails.additional_documents && (
                <li className="mb-2">
                  <span className="font-bold">Additional Documents</span> -{' '}
                  {loanDetails.additional_documents.join(', ')}
                </li>
              )}
            </ul>
          </div>
        </div>
      );
    };
export default Document
