import React, { useState, useEffect } from 'react';
import RegisterFrom from '../EnquiryForm/RegisterFrom';
import hero1 from "../../Images/Front page.png"
import hero2 from "../../Images/Front Page Slide (2).png"
import hero3 from "../../Images/Front Page Slide.png"
import { Link } from 'react-router-dom';

const slides = [
  {
    id: 1,
    image:hero1,
    title: "Apply for a home loan, personal loan, property loan, or business loan",
    // description: "Get your instant CIBIL score and detailed credit report with just a few clicks!",
    buttonText: "Contact Us"
  },
  {
    id: 2,
    title: "Unlocking Your Financial Potential",

    description: "Discovering the key to maximizing your financial growth and achieving long-term financial success.",

    image:hero2,

    buttonText: "Contact Us"
  },
  {
    id: 3,
    title: "Download our app to effortlessly apply for a loan.",
    description: "Easily apply for a loan anytime, anywhere by downloading our app, designed for a smooth and hassle-free experience",
    image:hero3,

    buttonText: "Contact Us"
  }
];

const CrouselComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  // Autoplay functionality
  useEffect(() => {
    const timer = setInterval(nextSlide, 5000);  
    return () => clearInterval(timer);  
  }, []);

  return (
    <div className='relative overflow-hidden ' style={{minHeight:"500px"}}>
      {/* Triangle Background */}
      <div className='absolute inset-0 -z-10'>
        <div className='absolute inset-0'>
          <div className='w-screen h-screen bg-[#0F63AC] clip-triangle'></div>
        </div>
      </div>

      <div className="relative max-w-[1260px] mx-auto">
        <div className="overflow-hidden pt-5 pb-5"style={{display:"flex",alignItems:"center",justifyContent:"center",flexWrap:"wrap"}}>
          {slides.map((slide, index) => (
            <div
              key={slide.id}
              className={`flex flex-col md:flex-row items-center justify-between p-6 transition-transform duration-500 ease-in-out ${index === currentSlide ? 'translate-x-0' : 'translate-x-full'} ${index < currentSlide ? '-translate-x-full' : ''}`}
              style={{ display: index === currentSlide ? 'flex' : 'none',flexBasis:"300px",flexGrow:1 }}
            >
              <div className="flex flex-col items-center md:items-start">
                <div style={{maxWidth:"300px",margin:"auto"}}>
                  <img src={slide?.image} alt='Blubucks'/>
                </div>
                <h2 className="text-xl md:text-1xl font-bold mb-4 text-gray-800 m-auto">{slide.title}</h2>
                <p className="text-base md:text-lg text-gray-800 mb-6 text-center " style={{textShadow: "0.5px 1px #fff"}}>{slide.description}</p>
                <Link  to='/contact-us'>
                <button className="bg-[#0F63AC] text-white border border-white px-4 md:px-6 py-2 md:py-3 rounded-lg hover:bg-red-600 focus:outline-none">
                  {slide.buttonText}
                </button>
                </Link>
              </div>
            </div>
            ))}
              <div className="w-full md:w-1/2 lg:w-3/5 flex justify-center md:justify-end" style={{flexBasis:"300px",flexGrow:1}}>
              <RegisterFrom title={"Apply for Loan"} label={false}/>
              </div>
        </div>

        {/* Dots */}
        <div className="absolute bottom-4 left-0 right-0 flex justify-center">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`h-3 w-3 mx-1 rounded-full cursor-pointer transition-colors duration-300 ${index === currentSlide ? 'bg-blue-600 transform scale-110' : 'bg-gray-400 hover:bg-blue-400'}`}
              onClick={() => setCurrentSlide(index)}
            ></div>
          ))}
        </div>
      </div>

      {/* Embedded CSS */}
      <style jsx>{`
        .clip-triangle {
          clip-path: polygon(-10% 100%, 110% 100%, 110% -10%);
        }
      `}</style>
    </div>
  );
};

export default CrouselComponent;
