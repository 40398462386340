import React, { useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  // width: 100%;
  margin: 5%;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
  font-size: clamp(20px, 4vw, 40px);
  font-weight: 700;
`;

const Section = styled.div`
  margin: 20px 0;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const SubTitle = styled.h2`
  font-size: clamp(10px, 4vw, 25px);
  font-weight: 500;
  color: red;
`;

const Paragraph = styled.p`
  font-size: 16px;
  margin: 10px 0;
`;

const PrivacyPolicy = () => {
  useEffect(()=>{

    scrollToTop()
  },[])
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'instant' });
    };
  
  return (
    <Container>
      <Title>Privacy Policy</Title>
      <Section>
        <SubTitle>Last updated: September 23, 2024</SubTitle>
        <Paragraph>
          Blubucks Asset Pvt Ltd is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose your personal information when you use our services. It also outlines your privacy rights and how the law protects you. By using our services, you agree to the collection and use of your information as described in this policy.
        </Paragraph>
        <SubTitle>Information Collection and Use:</SubTitle>
        <Paragraph>
          We collect personal data to provide and enhance our services. Personal data includes, but is not limited to, your name, email address, phone number, and postal address. We also collect usage data such as your IP address, browser type, and device information, which helps us analyze service performance and improve user experience.
        </Paragraph>
        <SubTitle>Cookies and Tracking Technologies:</SubTitle>
        <Paragraph>
          We use cookies and other tracking technologies to monitor your activity on our services. Cookies help us store user preferences and improve functionality. You may choose to disable cookies via your browser, but this may affect your experience with our services.
        </Paragraph>
        <SubTitle>Use of Personal Data:</SubTitle>
        <Paragraph>
          We use your personal information to provide and maintain our services, communicate with you, and manage your account. We may also use your data for analytics, marketing, and to improve our offerings.
        </Paragraph>
        <SubTitle>Sharing Your Information:</SubTitle>
        <Paragraph>
          We may share your personal data with third-party service providers, affiliates, or in the event of a business transfer. We only share information as necessary and take steps to ensure your data is protected.
        </Paragraph>
        <SubTitle>Data Retention:</SubTitle>
        <Paragraph>
          We retain your personal data for as long as necessary to fulfill the purposes outlined in this policy, comply with legal obligations, and resolve disputes.
        </Paragraph>
        <SubTitle>Contact Us:</SubTitle>
        <Paragraph>
          For any questions regarding this Privacy Policy, please email us at support@blubucks.in.
        </Paragraph>
      </Section>
    </Container>
  );
};

export default PrivacyPolicy;
