import React, { useEffect, useState } from "react";
import PersonalLoanFeature from "./PersonalLoanFeature";
import PersonalLoanBenifit from "./PersonalLoanBenifit";
import PersonalLoanEligibility from "./PersonalLoanEligibility";
import PersonalLoanEligibilityCriteria from "./PersonalLoanEligibilityCriteria";
import DocumentsRequired from "./DocumentsRequired";
import Charges from "./Charges";
import PersonalLoanReview from "./PersonalLoanReview";
import PersonalLoanFaq from "./PersonalLoanFaq";
import Document from "./Document";
const PersonalLoanNavba = () => {
  const [activeTab, setActiveTab] = useState("OVERVIEW");

  const tabs = [
     { name: 'OVERVIEW', id: 'features-section' },
    { name: 'FEATURES', id: 'features-section' },
    { name: 'ELIGIBILITY', id: 'eligibility-section' },
    { name: 'DOCUMENTS', id: 'documents-section' },
    { name: 'FEES AND CHARGES', id: 'fees-charges-section' },
    { name: 'REVIEWS', id: 'reviews-section' },
    { name: 'FAQ`s', id: 'faq-section' }
    // "OVERVIEW",
    // "SALES",
    // "EXPENSES",
    // "ORDERS",
    // "P&L CALCULATOR",
    // "PEERS COMPARISON",
    // "ESTIMATES",
    // "P&L",
  ];
  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <>
      <div className="App">
      <div className="flex space-x-4 border-b-2 font-semibold" style={{overflowX:"auto"}}>
          {tabs.map(tab => (
           <a
           style={{textWrap: "nowrap"}}
           href={`#${tab.id}`}
           key={tab.id}
           className={`py-2 px-4 ${
             activeTab === tab.name ? 'border-b-2 border-red-500 text-red-500' : 'text-gray-500'
           }`}
           onClick={() => setActiveTab(tab.name)}
         >
           {tab.name}
         </a>
          ))}
        </div>
      </div>
      <div id="features-section">
      <PersonalLoanFeature />

      </div>
      <div id="features-section">
      <PersonalLoanBenifit />
      </div>
      <div id="eligibility-section">
      <PersonalLoanEligibility />
      <PersonalLoanEligibilityCriteria />

      </div>
      <div id="documents-section">
        
      <Document loanType="personal_loan_salaried" />
      <DocumentsRequired />
      </div>
      <div id="fees-charges-section">
      <Charges />
      </div>
      <div id="reviews-section">
      <PersonalLoanReview />
      </div>
      <div id="faq-section">
      <PersonalLoanFaq />
      </div>
     
     
    
   
   
      
    </>
  );
};

export default PersonalLoanNavba;
