import React from 'react';
import Document from '../personalLoan/Document';

function HomeLoanDocumentation() {
  return (
    <div className=" max-w-[1260px] mx-auto p-4">
      
      <Document loanType="home_loan_salaried" />
      <Document loanType="home_loan_self_employed" />


      <div className="mt-8">
        <h2 className="text-2xl font-semibold mb-2">EMI Calculator for Home Loan</h2>
        <p className='text-lg'>
          The monthly part-payment that you make to repay your Home Loan is known as an Equated Monthly Instalment (EMI). This monthly payment includes repayment of the
          principal amount of your loan divided over the period of the loan, with the agreed interest amount on the outstanding amount of your home loan.
          BluBucksonline Home Loan EMI Calculator is essential to make an up-to-date decision to execute your Home Loan Plan at optimum levels. This EMI calculator online aids
          you to perform complex computing functions to calculate your Home Loan Equated Monthly Instalment accurately in an instant.
        </p>
      </div>
    </div>
  );
}

export default HomeLoanDocumentation;