import React, { useEffect, useState } from 'react';
import LoanFeature from './LoanFeature';
import HomeLoanBenifits from './HomeLoanBenifits';
import HomeLoanEligibility from './HomeLoanEligibility';
import HomeLoanDocumentation from './HomeLoanDocumentation';
import HomeLoanCharges from './HomeLoanCharges';
import HomeLoanReviews from './HomeLoanReviews';
import HomeLoanFaq from './HomeLoanFaq';
import HomeServices from './HomeServices';

const HomeLoanNavbar = () => {
  const [activeTab, setActiveTab] = useState('OVERVIEW');

  // Define the tab names and map them to the section IDs
  const tabs = [
    { name: 'OVERVIEW', id: 'features-section' },
    { name: 'FEATURES', id: 'features-section' },
    { name: 'ELIGIBILITY', id: 'eligibility-section' },
    { name: 'DOCUMENTS', id: 'documents-section' },
    { name: 'FEES AND CHARGES', id: 'fees-charges-section' },
    { name: 'REVIEWS', id: 'reviews-section' },
    { name: 'FAQ`s', id: 'faq-section' }
  ];
useEffect(()=>{

  scrollToTop()
},[])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  };


  return (
    <>
      <div className="App">
      <div className="flex space-x-4 border-b-2 font-semibold" style={{overflowX:"auto"}}>
          {tabs.map(tab => (
           <a
           style={{textWrap: "nowrap"}}
           href={`#${tab.id}`}
           key={tab.id}
           className={`py-2 px-4 ${
             activeTab === tab.name ? 'border-b-2 border-red-500 text-red-500' : 'text-gray-500'
           }`}
           onClick={() => setActiveTab(tab.name)}
         >
           {tab.name}
         </a>
          ))}
        </div>  
      </div>

      <div id="features-section">
        <LoanFeature />
      </div>
      <div id="features-section">
        <HomeLoanBenifits />
      </div>
      <div id="eligibility-section">
        <HomeLoanEligibility />
      </div>
      <div id="documents-section">
        <HomeLoanDocumentation />
      </div>
      <div id="fees-charges-section">
        <HomeLoanCharges />
      </div>
      <div id="reviews-section">
        <HomeLoanReviews />
      </div>
      <div id="faq-section">
        <HomeLoanFaq />
      </div>
      <div>
        <HomeServices />
      </div>
    </>
  );
};

export default HomeLoanNavbar;
