import React from 'react';
import boy from '../../creaditcardComponents/cardTypeImages/boy1.png';
import girl from '../../creaditcardComponents/cardTypeImages/girl1.png';
import quotes from '../../creaditcardComponents/cardTypeImages/quotes.png';

const BussinessLoanReview = () => {
    const reviews = [
        {
            name: "Shreya Mehta",
            rating: 4,
            review: "I was going through a low in my business and was unable to get financial support from lenders. Under these circumstances, Sahil from BluBucks suggested I apply for a top-up home loan and use that money to fund my business. Thanks to the top-up home loan facility that helped my business survive even in tough times.",
            images: girl,
            quote: quotes,
        },
        {
            name: "Ravi Patel",
            rating: 5,
            review: "BluBucks made the process of getting a home loan incredibly easy. Their team was professional, and the approval process was faster than expected. I would highly recommend them to anyone looking to secure a home loan. The EMI calculator also gave me a clear idea of what to expect.",
            images: boy,
            quote: quotes,
        },
        {
            name: "Anjali Kapoor",
            rating: 5,
            review: "Getting a home loan with BluBucks was smooth and efficient. I was nervous about the paperwork, but they walked me through everything and helped me secure a great rate. The customer service is exceptional and helped me at every step of the way.",
            images: girl,
            quote: quotes,
        },
        {
            name: "Rajesh Kumar",
            rating: 3,
            review: "BluBucks provided a decent service overall. The loan approval process was longer than expected, but the staff was responsive to my queries. I got a competitive interest rate, and the post-loan service was excellent.",
            images: boy,
            quote: quotes,
        },
        {
            name: "Neha Aggarwal",
            rating: 5,
            review: "I applied for a home loan through BluBucks, and I must say the process was extremely fast and straightforward. The team was always available to clear my doubts, and the interest rates offered were better than other lenders I explored.",
            images: girl,
            quote: quotes,
        },
        {
            name: "Vikram Chauhan",
            rating: 4,
            review: "I used BluBucks' EMI calculator to evaluate the loan, and it was incredibly helpful in making my decision. Their customer service was quick and efficient. I received loan approval within a week and could proceed with my home purchase without any hassles.",
            images: boy,
            quote: quotes,
        },
    ];
    

    return (
        <div className="max-w-screen-xl mx-auto rounded-lg p-6 flex flex-col bg-white">
            <h2 className="text-3xl font-bold mb-8">Personal Loan Reviews</h2>
            <div className='grid lg:grid-cols-2 gap-8'>
                {reviews.map((review, index) => (
                    <div key={index} className='bg-[#FAFAFA] border border-gray-200 p-6 rounded-lg shadow-md relative'>
                        <div className='flex items-start gap-4 mb-4'>
                            <img src={review.quote} alt="Quote Icon" className="w-10 " />
                            <p className='text-yellow-400 text-3xl ml-6'>
                                {'★'.repeat(review.rating)}{'☆'.repeat(5 - review.rating)}
                            </p>
                        </div>



                        <div className='w-[95%] flex justify-end ml-6 border-l-4 border-l-[#4376BA]'>
                            <p className='text-gray-800 mb-6 text-lg ml-2 w-[80%]'>{review.review}</p>
                        </div>



                        <div className='flex items-center gap-4 mt-4'>
                            <img className='w-16 h-16 rounded-full border border-gray-300 p-1' src={review.images} alt={review.name} />
                            <p className='font-semibold text-xl flex items-center'>
                                {review.name}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BussinessLoanReview;
