import React, { useEffect, useState } from 'react';
import BussinessLoanInfo from './BussinessLoanInfo';
import BussinesLoanEligibility from './BussinesLoanEligibility'
import BussinessLoan from './BussinessLoan';
import BussinessLoanCharges from './BussinessLoanCharges';
import BussinessLoanReview from './BussinessLoanReview';
import BussinesLoanfaq from './BussinesLoanfaq';
import HomeLoanDocumentation from '../homeLoan/HomeLoanDocumentation';
const BussinessLoanNavbar = () => {
  const [activeTab, setActiveTab] = useState('OVERVIEW');

  const tabs = [
    { name: 'OVERVIEW', id: 'features-section' },
    { name: 'FEATURES', id: 'features-section' },
    { name: 'ELIGIBILITY', id: 'eligibility-section' },
    { name: 'DOCUMENTS', id: 'documents-section' },
    { name: 'FEES AND CHARGES', id: 'fees-charges-section' },
    { name: 'REVIEWS', id: 'reviews-section' },
    { name: 'FAQ`s', id: 'faq-section' }
  ];
  useEffect(()=>{

    scrollToTop()
  },[])
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'instant' });
    };
  
  return (
    <>
      <div className="App">
      <div className="flex space-x-4 border-b-2 font-semibold" style={{overflowX:"auto"}}>
          {tabs.map(tab => (
           <a
           style={{textWrap: "nowrap"}}
           href={`#${tab.id}`}
           key={tab.id}
           className={`py-2 px-4 ${
             activeTab === tab.name ? 'border-b-2 border-red-500 text-red-500' : 'text-gray-500'
           }`}
           onClick={() => setActiveTab(tab.name)}
         >
           {tab.name}
         </a>
          ))}
        </div>
      </div>

      
      <div id="features-section">
       <BussinessLoanInfo/>
      </div>
      <div id="features-section">
       <BussinessLoan/>
      </div>
      <div id="eligibility-section">
       <BussinesLoanEligibility/>
      </div>
      <div id="documents-section">
        {/* <HomeLoanDocumentation /> */}
      </div>
      <div id="fees-charges-section">
       <BussinessLoanCharges/>
      </div>
      <div id="reviews-section">
       <BussinessLoanReview/>
      </div>
      <div id="faq-section">
       <BussinesLoanfaq/>
      </div>
      

    </>
  );
};

export default BussinessLoanNavbar;
