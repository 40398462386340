import Modal from "react-bootstrap/Modal";
import React from "react";
import RegisterFrom from "./RegisterFrom";
import "./RequestCallback.css";
import Images from "../../Images/requestcallback.png"
function RequestaCallback({ showModal, setshowModal,title }) {
  const btnStyle = {
    position: "absolute",
    top: "-16px",
    right:"-11px",

    color: "#ffff",
    cursor: "pointer",
  };
  const modalBackdropStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.9)", // Adjust the alpha value to change the darkness
    zIndex:"11000"
  };
  const bodydiv = {
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    flexWrap:"wrap",
    position: "relative"
  }
  return (
    <div className="ed-reuest-callback-container">
      <Modal
        style={modalBackdropStyle}
        show={showModal}
        size="lg"
        className="ed-reuest-callback-modal"
        onHide={() => setshowModal(false)}
        centered
      >
        <Modal.Body>

          <div style={bodydiv}>
          
          <span
            className="close-video-modal"
            style={btnStyle}
            onClick={() => setshowModal(false)}
          >
            <i class="fa-solid fa-xmark text-dark"></i>
          </span>
<div style={{flexBasis:"250px",flexGrow:1}}>
  <img src={Images} alt="blubucks"/>
</div>
<div  style={{flexBasis:"250px",flexGrow:1}}>


          <RegisterFrom title={title} />
</div>

          </div>
          
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RequestaCallback;
