import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./RegisterForm.css";
import FormValidation from "../../Utils/FormValidation";
import FormInputChange from "../../Utils/FormInputChange";

const RegisterFrom = ({ title,label=true}) => {
  const [errors, setErrors] = useState({});
  const [buttondisable, setbuttondisable] = useState({
    disable: false,
    text: "Submit",
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    city: "",
    mobile_no: "",
    country_code: "91",
    message: "",
    agree: false,
  });

  const handleInputChange = (e) => {
    FormInputChange(e, formData, setFormData, errors, setErrors);
  };

  const errorTexts = {
    mobile_no: "Mobile number is required*",
    agree: "You must agree to the terms of service and privacy policy",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (FormValidation(formData, errorTexts, setErrors)) {
      setbuttondisable({
        ...buttondisable,
        disable: true,
        text: "Please wait...",
      });

      try {
        const { agree, ...formDataWithoutAgree } = formData;
        const response = await fetch("https://blubucks.in/api/enquiries/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formDataWithoutAgree),
        });

        if (response.ok) {
          const result = await response.json();
          setbuttondisable({
            ...buttondisable,
            disable: false,
            text: "Submit",
          });
          // Optionally reset the form
          setFormData({
            name: "",
            email: "",
            city: "",
            mobile_no: "",
            country_code: "91",
            message: "",
            agree: false,
          });
        
        } else {
          const errorData = await response.json();
          setErrors(errorData.errors || {});
          setbuttondisable({
            ...buttondisable,
            disable: false,
            text: "Submit",
          });
        }
      } catch (error) {
        setbuttondisable({
          ...buttondisable,
          disable: false,
          text: "Submit",
        });
      }
    }
  };
  return (
    <div className="ed-register-frm internship-register-form border rounded p-3 bg-white m-auto">
      <h2 className="title-1 mb-2 text-1xl md:text-2xl font-bold">{title}</h2>
      <form onSubmit={handleSubmit}>
        <div class="mb-3">
        {label && (
       <label for="validationServer01" class="form-label">
            Name<span className="text-danger">*</span>
          </label>
            )} 
          <input
            type="text"
            name="name"
            id="name"
            className={`form-control ${errors.name && "is-invalid"}`}
            aria-describedby="validationname"
            placeholder="Enter your Name*"
            value={formData.name}
            onChange={handleInputChange}
          />
          <div id="validationname" className="invalid-feedback">
            {errors.name}
          </div>
        </div>
        <div class="mb-3">
        {label && (
          <label for="validationServer01" class="form-label">
            City<span className="text-danger">*</span>
          </label>
        )}
          <input
            type="text"
            name="city"
            id="city"
            className={`form-control ${errors.city && "is-invalid"}`}
            aria-describedby="validationcity"
            placeholder="Enter your City*"
            value={formData.city}
            onChange={handleInputChange}
          />
          <div id="validationcity" className="invalid-feedback">
            {errors.city}
          </div>
        </div>
        <div class="mb-3">
        {label && (  <label for="validationServer01" class="form-label">
            Email ID<span className="text-danger">*</span>
          </label>)}
          <input
            type="text"
            name="email"
            id="email"
            className={`form-control ${errors.email && "is-invalid"}`}
            aria-describedby="validationemail"
            placeholder="Enter your Email ID"
            value={formData.email}
            onChange={handleInputChange}
          />
          <div id="validationemail" className="invalid-feedback">
            {errors.email}
          </div>
        </div>

        <div class="mb-3">
        {label && (   <label for="mobile" class="form-label">
            Mobile Number<span className="text-danger">*</span>
          </label>)}
          <div class="input-group has-validation">
            <select
              id="flag"
              class="input-group-text"
              name="country_code"
              aria-label="Default select example"
              value={formData.country_code}
              onChange={handleInputChange}
            >
              <option value="91" selected>
                +91
              </option>
            </select>
            <input
              type="text"
              name="mobile_no"
              placeholder="Enter your mobile number"
              className={`form-control rounded-end ${
                errors.mobile_no && "is-invalid"
              }`}
              id="mobile"
              value={formData.mobile_no}
              onChange={handleInputChange}
              aria-describedby="mobile"
            />
            <div id="mobile" class="invalid-feedback">
              {errors.mobile_no}
            </div>
          </div>
        </div>
        <div class="mb-3">
        {label && (  <label for="graduation" class="form-label">
            Message<span className="text-danger">*</span>
          </label>)}
          <textarea
            type="text"
            name="message"
            className={`form-control ${errors.message && "is-invalid"}`}
            id="message"
            placeholder="Enter your Message"
            value={formData.message}
            aria-describedby="validationgraduation"
            onChange={handleInputChange}
            rows={5}
          />
          <div id="validationgraduation" className="invalid-feedback">
            {errors.message}
          </div>
        </div>

        <button
          className="rounded bg-[#0F63AC] mb-2 p-2 ps-3 pe-3 border-0 register-btn"
          type="submit"
          disabled={buttondisable.disable}
        >
          {buttondisable.text}
        </button>
        <div class="form-check">
          <input
            className={`form-check-input   ${errors.agree && "is-invalid"}`}
            type="checkbox"
            id="flexCheckDefault"
            name="agree"
            checked={formData.agree}
            onChange={handleInputChange}
          />
          <label
            className={`form-check-label  term-policy p-0 m-0 ${
              errors.agree && "is-invalid"
            }`}
            for="flexCheckDefault"
          >
            By providing your contact detail, you agree to our
            <NavLink className="link-t1 me-1 ms-1" to="">
              Terms of Use
            </NavLink>
            &
            <NavLink className="link-t1 ms-1" to="">
              PrivacyPolicy
            </NavLink>
          </label>
        </div>
      </form>
    </div>
  );
};

export default RegisterFrom;
