import React, { useState, useEffect } from 'react';
import financeImage from '../../Images/banks/img (1).jpg';
import image1 from "../../Images/Prtner bank.png"
import image2 from "../../Images/Prtner bank (2).png"
import image3 from "../../Images/Succesful loan disbursel.png"
import image4 from "../../Images/Succesful loan disbursel (2).png"
function AboutSection() {
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);

  const animateCounter = (setCount, endValue, duration) => {
    const startTime = performance.now();

    const step = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const easedProgress = easeInOutQuad(progress);
      setCount(Math.floor(easedProgress * endValue));

      if (progress < 1) {
        requestAnimationFrame(step);
      } else {
        setTimeout(() => animateCounter(setCount, endValue, duration), 500); // Repeat animation after a delay
      }
    };

    requestAnimationFrame(step);
  };

  const easeInOutQuad = (t) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  useEffect(() => {
    const duration = 10000; // Duration in milliseconds (1 second)
    animateCounter(setCount, 50, duration);
    animateCounter(setCount2, 10, duration);
    animateCounter(setCount3, 500, duration);
    animateCounter(setCount4, 15, duration);
  }, []);

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-gray-50">
      {/* Background Image */}
      <img
        src={financeImage}
        alt="Finance Background"
        className="absolute inset-0 object-cover w-full h-full"
        style={{ filter: 'brightness(50%)' }} // Adjust brightness if needed
      />
      
      {/* Content */}
      <div className="relative z-10 max-w-[1260px] mx-auto flex flex-col items-center lg:flex-row gap-10 bg-white bg-opacity-80 p-8 rounded-lg">
        {/* Left Section */}
        <div className="flex-1 flex flex-col items-center lg:items-start text-center lg:text-left">
          <h1 className="text-4xl font-bold mb-4">
            We help our clients achieve their desired goals.
          </h1>
          <p className="text-lg">
            With a PAN India presence across 2000+ cities, BluBucks is one of the
            leading credit lending establishments founded by professionals and
            financial experts.
          </p>
        </div>

        {/* Right Section */}
        <div className="flex-1 flex flex-col items-center gap-10">
          <div className="flex justify-center items-center gap-10">
            <div className="flex flex-col items-center">
              <img
                src={image1}
                alt="Bank"
                className="w-24 h-24"
              />
              <div className="flex gap-1 mt-4">
                <h2 className="text-3xl font-bold">{count}+</h2>
              </div>
              <p className="text-lg mt-2">Partner Banks & NBFCs</p>
            </div>
            <div className="flex flex-col items-center">
              <img
                src={image2}
                alt="Globe"
                className="w-24 h-24"
              />
              <div className="flex gap-1 mt-4">
                <h2 className="text-3xl font-bold">{count2}+</h2>
              </div>
              <p className="text-lg mt-2">Branches Across India</p>
            </div>
          </div>
          <div className="flex justify-center items-center gap-10">
            <div className="flex flex-col items-center">
              <img
               src={image3}
                alt="Money"
                className="w-24 h-24"
              />
              <div className="flex gap-1 mt-4">
                <h2 className="text-3xl font-bold">₹{count3} CR+</h2>
              </div>
              <p className="text-lg mt-2">Successful Disbursal</p>
            </div>
            <div className="flex flex-col items-center">
              <img
             src={image4}
                alt="Chart"
                className="w-24 h-24"
              />
              <div className="flex gap-1 mt-4">
                <h2 className="text-3xl font-bold">{count4}+</h2>
              </div>
              <p className="text-lg mt-2">Years of Experience</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
