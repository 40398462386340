import React, { useState } from 'react';
import RequestaCallback from '../../components/EnquiryForm/RequestaCallback';

const PageLoanApply = ({title}) => {
    const [showmodal,setShowModal] = useState(false)

    return (
        <>
              <RequestaCallback title={`Apply for ${title}`} setshowModal={setShowModal} showModal={showmodal}/>

        <div className="max-w-[1260px] mx-auto  shadow-md rounded-lg  p-4  bg-[#EEF4FF] border border-[#8A98BF] mt-4">
            <h2 className="text-3xl  font-semibold mb-2">{title} Eligibility and Documents</h2>
            <div className='flex justify-between mt-8 flex-wrap '>
                <p className="text-gray-700 mb-4 text-xl">
                Read on to know the criteria required to apply for our {title}.
                </p>
                <button onClick={()=>setShowModal(true)} className="bg-red-500 text-white py-4 px-16 rounded text-xl hover:bg-red-600 font-bold">
                    Apply
                </button>
            </div>
        </div>
        </>
    );
};

export default PageLoanApply;
