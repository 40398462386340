import React from 'react';
import im1 from "../../Images/1.png"
import im2 from "../../Images/2.png"
import im3 from "../../Images/3.png"
const insuranceData = [
  {
    title: 'Property Dealer',
    description: "A property dealer can earn by referring clients to you through a referral commission or partnership program.",
    image: im1, 
    link: '/refer-and-earn',
    // price: 'Starting from ₹ 450/month*',
  },
  {
    title: 'Chartered  Accountant',
    description: 'A Chartered Accountant can earn by referring clients to you through a referral commission or a partnership program.',
    image: im2, 
    link: '/refer-and-earn',
    // price: 'Starting from ₹ 450/month*',
  },
  {
    title: 'LIC Agent & Freelancer',
    description: 'An LIC agent or freelancer can earn by referring clients to you through a referral commission or a partnership program.',
    image: im3, 
    link: '/refer-and-earn',
    // price: 'Starting from ₹ 450/month*',
  }
];

function InsuranceCard() {
  return (
     <div className='bg-[#0F63AC]'>
        <div className="p-4 md:p-8 lg:p-12 max-w-[1260px] mx-auto">
      <h1 className="text-3xl md:text-5xl font-bold mb-8 text-white">Earn with us</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {insuranceData.map((insurance, index) => (
          <div 
            key={index} 
            className="bg-white shadow-xl rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl relative"
          >
            <div className="p-6 bg-gradient-to-r from-[#E0F3F7] to-white">
              <img 
                src={insurance.image} 
                alt={insurance.title} 
                className="w-19 h-19 object-contain mb-4 mx-auto"
              />
              <h2 className="text-2xl font-semibold mb-2 text-gray-800 text-center">{insurance.title}</h2>
              <p className="text-gray-600 text-center mb-4">{insurance.description}</p>
              <p className="text-lg font-semibold text-gray-800 text-center mb-4">{insurance.price}</p>
              <a 
                href={insurance.link} 
                className="text-white bg-[#0F63AC] hover:bg-red-600 py-2 px-4 rounded-full block text-center mx-auto mt-4 max-w-[200px]"
              >
                Learn More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
     </div>
  );
}

export default InsuranceCard;
