import React from 'react';
import { FaPen } from 'react-icons/fa'; 

const BussinessLoanCriteria = () => {
  return (
    <div className="max-w-[1260px] mx-auto px-4 py-12 bg-gray-50 rounded-lg shadow-lg">
      <h1 className="text-4xl font-semibold   mb-8">
        Eligibility Criteria for BluBucksPersonal Loan DSA Registration
      </h1>
      <p className="text-lg mb-8 text-gray-700 leading-relaxed">
        BluBucksDistribution Services Private Limited (RDSPL), a registered DSA, has partnerships with over 265 leading Banks and NBFCs to offer loans in more than 2000 cities across India. Our retail portfolio includes Personal Loans, Business Loans, Home Loans, Loan against Property or Mortgage Loans, New Car Loan, Used Car Loan & Car Refinance, Credit Cards, and more. Below are the eligibility criteria to operate as a Loan DSA Partner or Loan DSA Franchise:
      </p>
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-red-600 mb-6 flex items-center">
          <FaPen className="mr-2" /> Eligibility Criteria to Become a DSA Loan Agent:
        </h2>
        <ul className="list-disc pl-8 space-y-6 text-gray-700">
          <li>
            <h3 className="text-xl font-semibold text-red-500 flex items-center">
              <FaPen className="mr-2" /> Age
            </h3>
            <p className="ml-6">You must be over 25 years of age.</p>
          </li>
          <li>
            <h3 className="text-xl font-semibold text-red-500 flex items-center">
              <FaPen className="mr-2" /> Nationality
            </h3>
            <p className="ml-6">You must be a Resident Citizen of India.</p>
          </li>
          <li>
            <h3 className="text-xl font-semibold text-red-500 flex items-center">
              <FaPen className="mr-2" /> Educational Qualifications
            </h3>
            <p className="ml-6">No educational requirements are needed to qualify as a BluBucksLoan DSA Partner.</p>
          </li>
          <li>
            <h3 className="text-xl font-semibold text-red-500 flex items-center">
              <FaPen className="mr-2" /> Professional Qualifications
            </h3>
            <p className="ml-6">Whether you are a working professional or a business owner, you are welcome to register as a BluBucksLoan DSA Franchise.</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BussinessLoanCriteria;
