import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import referimg from "../../Images/refer.png"
import RequestaCallback from '../EnquiryForm/RequestaCallback';
const Container = styled.div`
  width:100%

  margin: 5%;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
       font-size: clamp(20px,4vw,40px);
    font-weight: 700;

`;

const Section = styled.div`
  margin: 20px 0;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const SubTitle = styled.h2`
  font-size: clamp(10px,4vw,25px);
    font-weight: 500;
    color:red
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin: 10px 0;
  font-size: 16px;
`;

const Button = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  
  &:hover {
    background-color: #0056b3;
  }
`;
const ImageContainer = styled.div`
width:100%;
overflow:hidden;
`;
const Image = styled.img`
object-fit:cover;
width:60%;
margin:auto;
border-radius:1.5rem
`;
const Refer = () => {
  const [showmodal,setShowModal] = useState(false)
  useEffect(()=>{

    scrollToTop()
  },[])
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'instant' });
    };
  
  return (
    <Container>
      <RequestaCallback title="Contact to BluBucks" setshowModal={setShowModal} showModal={showmodal}/>

      <Title>Refer and Earn!</Title>
      <ImageContainer>
        <Image src={referimg} alt="Blubucks"/>
      </ImageContainer>
      <Section>
        <SubTitle>How It Works:</SubTitle>
        <List>
          <ListItem>
            <strong>1. Referral Commission:</strong> We can offer the property dealer a referral commission for each client they bring to you who successfully completes a transaction (buying, selling, or renting). The amount of commission can be a fixed fee or a percentage of the transaction value, depending on the agreement. For example, if a property dealer refers a client who buys a property from you, you might offer them 1% to 2% of the sale price as commission.
          </ListItem>
          <ListItem>
            <strong>2. Partnership Agreement:</strong> Establishing a formal partnership can encourage long-term referrals. We can agree to share a portion of profits or commissions for each deal that comes through the property dealer’s referrals. For rentals, the property dealer may receive a percentage of the first month’s rent as a referral fee.
          </ListItem>
          <ListItem>
            <strong>3. Bonuses and Incentives:</strong> Offering bonuses for bringing multiple clients or referring high-value deals. For example, if a dealer refers more than five clients within a certain time frame, you might offer them a bonus payment or a higher commission rate.
          </ListItem>
          <ListItem>
            <strong>4. Terms and Conditions:</strong> To avoid any confusion, it's important to establish the terms of referral in a written agreement. This should outline the commission structure, payment timelines, and any conditions that need to be met for the referral program.
          </ListItem>
        </List>
        <button onClick={()=>setShowModal(true)} className="bg-red-500 text-white py-4 px-16 m-auto rounded text-xl hover:bg-red-600 font-bold">
            Apply
          </button>
      </Section>
     
    </Container>
  );
};

export default Refer;
