import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import image from "../../Images/referandearn.jpg"
import RequestaCallback from '../EnquiryForm/RequestaCallback';
const Container = styled.div`
  // width:100%;
  margin: 5%;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
   text-align: center;
  color: #333;
       font-size: clamp(20px,4vw,40px);
    font-weight: 700;
`;
const Image = styled.img`
width:100%;
object-fit:cover;
border-radius:0.5rem
`;
const Section = styled.div`
  margin: 20px 0;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const SubTitle = styled.h2`
   font-size: clamp(10px,4vw,25px);
    font-weight: 500;
    color:red
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  margin: 10px 0;
  font-size: 16px;
`;

const Button = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  
  &:hover {
    background-color: #0056b3;
  }
`;

const Franchisee = () => {
  const [showmodal,setShowModal] = useState(false)
  useEffect(()=>{

    scrollToTop()
  },[])
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'instant' });
    };
  
  return (
    <Container>
      <RequestaCallback title="Contact to BluBucks" setshowModal={setShowModal} showModal={showmodal}/>

      <Title>Start Your Loan Business With <span className='text-primary'>Blu</span>Bucks</Title>
      <Image src={image} alt="blubucks"  />
      <Section>
        
        <SubTitle>How It Works:</SubTitle>
        <List>
          <ListItem>
            <strong>1. No Upfront Franchise Fee:</strong> The franchisee (partner) doesn't pay an initial fee to join the franchise. They can start offering the loan company’s services without major financial investment.
          </ListItem>
          <ListItem>
            <strong>2. Revenue Sharing:</strong> The franchisee earns a percentage of the loan amount or interest collected from customers they bring in. Typically, this percentage can vary based on the loan type, loan amount, or other factors set by the parent loan company. For example, the franchisee might earn <strong>5% to 10%</strong> of the interest or processing fee collected on each loan.
          </ListItem>
          <ListItem>
            <strong>3. Marketing & Customer Acquisition:</strong> The franchisee is responsible for finding potential borrowers and promoting the loan services. The loan company may provide marketing materials, training, or support to help with lead generation.
          </ListItem>
          <ListItem>
            <strong>4. Loan Processing:</strong> Once the franchisee brings in a lead, the loan company handles the loan application, approval, and disbursement process. The franchisee only focuses on acquiring customers and doesn't have to worry about the operational side of lending.
          </ListItem>
          <ListItem>
            <strong>5. Support & Resources:</strong> The loan company provides ongoing training, tools, and support to ensure the franchisee can effectively attract and manage customers. The company might also offer a CRM system for managing leads, an online platform for tracking progress, and other resources.
          </ListItem>
          <ListItem>
            <strong>6. Performance-Based Earnings:</strong> The more loans the franchisee generates, the higher their earnings. Incentives or bonuses may be offered for hitting certain loan volume targets.
          </ListItem>
          <ListItem>
            <strong>7. Ongoing Support:</strong> Franchisees can receive periodic training updates and have access to customer support to resolve any issues.
          </ListItem>
        </List>
      </Section>
      <Section>
        <SubTitle>Benefits of the Free Franchise Model:</SubTitle>
        <List>
          <ListItem>- Low Risk: No upfront fees reduce financial risk for the franchisee.</ListItem>
          <ListItem>- High Earning Potential: Earnings grow as the franchisee brings in more clients.</ListItem>
          <ListItem>- No Operational Responsibility: The loan company handles loan processing, reducing the workload for the franchisee.</ListItem>
          <ListItem>- Scalability: Franchisees can focus on marketing and client acquisition, making it easier to scale their business.</ListItem>
        </List>
      </Section>
      <button onClick={()=>setShowModal(true)} className="bg-red-500 text-white py-4 px-16 m-auto rounded text-xl hover:bg-red-600 font-bold">
            Apply
          </button>
    </Container>
  );
};

export default Franchisee;
