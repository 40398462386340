import { useState } from 'react';
import percentageTag from '../../creaditcardComponents/cardTypeImages/percentageTag.png'
import coinsHands from '../../creaditcardComponents/cardTypeImages/coinsHands.png';
import coinBlog from '../../creaditcardComponents/cardTypeImages/coinsBlog.png';
import keycoin from '../../creaditcardComponents/cardTypeImages/keyCoin.png';
import keycoin1 from '../../creaditcardComponents/cardTypeImages/keyCoin (1).png';
import groupImg from '../../creaditcardComponents/cardTypeImages/groupImg.png';
 
function PersonalLoanFeature({feature}) {
  const [features] = useState([
    {
      icon:  percentageTag, 
      title: 'Offers for New Customers',
    },
    {
      icon:   coinsHands, 
      title: '3 Unique Variants',
    },
    {
      icon: coinBlog,  
      title: 'Loan of up to Rs. 40 Lakh',
    },
    {
      icon:    keycoin,  
      title: 'Tenure - Max 4 Years',
    },

    {
        icon:    groupImg,  
        title: 'No Guarantor / Collateral',
      },
     
      {
        icon:    keycoin1,  
        title: 'No Hidden Charges',
      },
  ]);

  return (
    <div className="container  px-4 py-8 max-w-[1260px] mx-auto">
      <h1 className="text-4xl mt-8 font-semibold">Personal Loan Features</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {features.map((feature, index) => (
          <div key={index} className="bg-white rounded-md shadow-md p-4 mt-16">
            <div className="flex mb-4">
              <img
                src={feature.icon}
                alt={feature.title}
                 
              />
            </div>
            <h3 className="text-xl mb-2">{feature.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PersonalLoanFeature;
