import { useState } from 'react';
 
import HomeLoanDsaAbout from './HomeLoanDsaAbout'
 import LoanDsaCriteria from './LoanDsaCriteria'
 import HomeLoanDsaFaq from './HomeLoanDsaFaq';
function HomeLoanDsa() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [profession, setProfession] = useState('');
  const [city, setCity] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [agreed, setAgreed] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted:', {
      firstName,
      middleName,
      lastName,
      panNumber,
      profession,
      city,
      mobileNumber,
      email,
      state,
      agreed,
    });
  };

  return (
    <>
     <div className="max-w-[1260px] mx-auto p-4">
      <h1 className="text-3xl font-semibold text-center mb-4">
        We are India's Leading Personal Loan DSA Agent
      </h1>
      <p className="text-center mb-6">
        Earning Opportunity for all existing agents with BluBucksLoan Partner Program
      </p>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="w-1/2">
            <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="w-1/2">
            <label htmlFor="middleName" className="block mb-2 text-sm font-medium text-gray-900">
              Middle Name
            </label>
            <input
              type="text"
              id="middleName"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="w-1/2">
            <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="w-1/2">
            <label htmlFor="mobileNumber" className="block mb-2 text-sm font-medium text-gray-900">
              Mobile Number
            </label>
            <input
              type="text"
              id="mobileNumber"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="w-1/2">
            <label htmlFor="panNumber" className="block mb-2 text-sm font-medium text-gray-900">
              Pan Number
            </label>
            <input
              type="text"
              id="panNumber"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={panNumber}
              onChange={(e) => setPanNumber(e.target.value)}
            />
          </div>
          <div className="w-1/2">
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="w-1/2">
            <label htmlFor="profession" className="block mb-2 text-sm font-medium text-gray-900">
              Select Profession
            </label>
            <select
              id="profession"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={profession}
              onChange={(e) => setProfession(e.target.value)}
            >
              <option value="">Select a Profession</option>
              <option value="Doctor">Doctor</option>
              <option value="Engineer">Engineer</option>
              <option value="Teacher">Teacher</option>
              {/* Add more profession options here */}
            </select>
          </div>
          <div className="w-1/2">
            <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900">
              Select State
            </label>
            <select
              id="state"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              <option value="">Select a State</option>
              <option value="Delhi">Delhi</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Karnataka">Karnataka</option>
              {/* Add more state options here */}
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900">
            Select City
          </label>
          <select
            id="city"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          >
            <option value="">Select a City</option>
            <option value="New Delhi">New Delhi</option>
            <option value="Mumbai">Mumbai</option>
            <option value="Bangalore">Bangalore</option>
            {/* Add more city options here */}
          </select>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="agreed"
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 rounded"
            checked={agreed}
            onChange={() => setAgreed(!agreed)}
          />
          <label htmlFor="agreed" className="ml-2 text-sm font-medium text-gray-900">
            I agree to the{' '}
            <a
              href="https://ruloans.com/terms-and-conditions/"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              terms & conditions
            </a>{' '}
            and{' '}
            <a
              href="https://ruloans.com/privacy-policy/"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              privacy policy
            </a>
            .
          </label>
        </div>
        <button
          type="submit"
          className="bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          SUBMIT
        </button>
      </form>
    </div>
     <HomeLoanDsaAbout/>
     <LoanDsaCriteria/>
     <HomeLoanDsaFaq/>
    </>
    
  );
}

export default HomeLoanDsa;
