import React from 'react'
import Document from '../personalLoan/Document'

const LoanPropert = () => {
  return (
    <div className="max-w-[1260px] mx-auto p-4 text-lg">
    <h1 className="text-3xl font-semibold text-center mb-4">
      Eligibility Criteria for Loan against Property
    </h1>
    <div className="flex flex-col">
      <div className="  p-4 rounded-lg">
        <h2 className="text-xl font-bold mb-2">Nationality</h2>
        <p>You need to be a Citizen of India with documents to prove your claim.</p>
      </div>
      <div className=" p-4  ">
        <h2 className="text-xl font-bold mb-2">Occupation and Income</h2>
        <p>
          Your lender will require you to furnish details regarding your occupation
          and income to prove your professional and financial stability to
          determine your creditworthiness.
        </p>
      </div>
      <div className="  p-4 rounded-lg">
        <h2 className="text-xl font-bold mb-2">Credit History</h2>
        <p>
          Your three-digit Credit Score, indicative of your track record in
          respect of repayment of loans, and other forms of credit will be a
          deciding factor to prove your eligibility for a LAP.
        </p>
      </div>
      <div className="  p-4 rounded-lg">
        <h2 className="text-xl font-bold mb-2">Banking Relationship</h2>
        <p>
          Should you have a healthy relationship with your lender, you will not
          be disapproved for a LAP. Additionally, your lender will offer you
          better terms and conditions in respect of loan value, interest rates,
          period of the loan, hidden charges, and processing fees.
        </p>
      </div>
      <div className="  p-4 rounded-lg">
        <h2 className="text-xl font-bold mb-2">Market Value of Property</h2>
        <p>
          Your lender retains the right to decide the loan amount and terms and
          conditions of your mortgage loan based on the market value of your
          collateral property. Besides, the market value of the mortgaged property
          must be higher than the loan amount calculated on the current value of
          your property.
        </p>
      </div>
      <div className="  p-4 rounded-lg">
        <h2 className="text-xl font-bold mb-2">Title of Property</h2>
        <p>
          Your lender will require you to be the current existent owner of the
          property, and in case of a co-application, you will require to prove
          multiple ownership clear title. Besides, the property must not be
          mortgaged with any other financial institution.
        </p>
      </div>
    </div>

    <Document loanType="loan_against_property_salaried" />
    <Document loanType="loan_against_property_self_employed" />

    <h2 className="text-2xl font-bold mt-8 mb-4">
      Loan Against Property EMI Calculator
    </h2>
    <p>
      A Loan Against Property may be termed as a Mortgage Loan since to avail an
      LAP, you need to mortgage your property to cover risk of non-payment or
      default in repayment of the funds borrowed. For any lender to approve such
      a borrowing, the lender will first analyse your personal and financial
      profile, which will include criteria such nationality, age, occupation,
      income, and market value of the collateral you are willing to keep. A
      mortgage loan calculator then calculates the financial implications of
      such a loan based on certain parameters based on eligibility criteria to
      enable approval of your Mortgage Loan.
    </p>

    <h3 className="text-xl font-bold mb-2">How is Loan Against Property EMI Calculated?</h3>
    <p>
      Loan Against Property EMI (Equated Monthly Installment) is calculated using
      the following Compound Interest formula:
    </p>
    <p >
      EMI = [P * r * (1 + r)^n] / [(1 + r)^n - 1]
    </p>
    <h4 className="text-lg font-bold mt-4 mb-2">Where:</h4>
    <ul className="list-disc pl-8">
      <li>EMI - Equated Monthly Installment</li>
      <li>P - Loan Against Property principal amount</li>
      <li>
        r - Monthly interest rate (Annual interest rate divided by 12, expressed
        as a decimal)
      </li>
      <li>n - Loan Against Property tenure in months</li>
    </ul>
  </div>
  )
}

export default LoanPropert