import { useState } from 'react';
 import RateUp from '../../creaditcardComponents/cardTypeImages/RatesUp.png';
 import Documents from '../../creaditcardComponents/cardTypeImages/Documents.png';
 import Calander from '../../creaditcardComponents/cardTypeImages/Calender.png';
 
function LoanFeature() {
  const [features] = useState([
    {
      icon:  RateUp, 
      title: 'Attractive Interest Rates',
    },
    {
      icon:   Documents, 
      title: 'Minimal Documentation',
    },
    {
      icon: Calander,  
      title: '30 Years of Tenure',
    },
    
  ]);

  return (
    <div className="container  px-4 py-8 max-w-[1260px] mx-auto">
      <h1 className="text-4xl mt-8 font-semibold">Home Loan Features</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {features.map((feature, index) => (
          <div key={index} className="bg-white rounded-md shadow-md p-4 mt-16">
            <div className="flex mb-4">
              <img
                src={feature.icon}
                alt={feature.title}
                 
              />
            </div>
            <h3 className="text-xl mb-2">{feature.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LoanFeature;
