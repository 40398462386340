import React, { useEffect, useState } from 'react'
import PropertyFeature from './PropertyFeature';
import Features from '../personalLoan/Features';
import percentageTag from '../../creaditcardComponents/cardTypeImages/percentageTag.png'
import coinsHands from '../../creaditcardComponents/cardTypeImages/coinsHands.png';
import coinBlog from '../../creaditcardComponents/cardTypeImages/coinsBlog.png';
import keycoin from '../../creaditcardComponents/cardTypeImages/keyCoin.png';
import keycoin1 from '../../creaditcardComponents/cardTypeImages/keyCoin (1).png';
import groupImg from '../../creaditcardComponents/cardTypeImages/groupImg.png';
import FeatureUser from '../personalLoan/FeatureUser';
import Document from '../personalLoan/Document';
import PageLoanApply from '../personalLoan/PageLoanApply';
import Charges from '../personalLoan/Charges';
import LoanPropertyReview from './LoanPropertyReview';
import LoanPropertfaq from './LoanPropertfaq';
const WorkingCapitalNavbar = () => {
    const [activeTab, setActiveTab] = useState('OVERVIEW');

    const tabs = [
      { name: 'OVERVIEW', id: 'features-section' },
      { name: 'FEATURES', id: 'features-section2' },
      { name: 'ELIGIBILITY', id: 'eligibility-section' },
      { name: 'DOCUMENTS', id: 'documents-section' },
      { name: 'FEES AND CHARGES', id: 'fees-charges-section' },
      { name: 'REVIEWS', id: 'reviews-section' },
      { name: 'FAQ`s', id: 'faq-section' }
    ];
    useEffect(()=>{
  
      scrollToTop()
    },[])
    
      const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
      };

const data ={
    "workingCapitalOverdraftLimit": {
      "features": [
        "Flexible borrowing – Access funds as needed up to a predefined limit.",
        "Interest on usage – Interest is charged only on the amount utilized, not the entire limit.",
        "Revolving facility – Can withdraw and repay multiple times within the limit.",
        "No fixed repayment schedule – Repay as per your convenience, based on cash flow.",
        "Quick access – Immediate availability of funds when required.",
        "Collateral options – Can be secured or unsecured based on the business profile.",
        "Helps maintain liquidity – Supports smooth operations during cash flow fluctuations."
      ],
      "eligibilityCriteria": {
        "personal": [
          "Business ownership – Must own or manage a registered business.",
          "Business vintage – The business should have been operational for a minimum period, typically 1-3 years.",
          "Stable income – Proof of regular cash flow or revenue is required.",
          "Good credit score – A strong personal and business credit score improves eligibility.",
          "Collateral (if required) – Assets or guarantees may be needed for secured limits.",
          "Business financials – Submission of balance sheets, profit & loss statements, and tax returns.",
          "Compliance with lender’s policies – Must meet any additional bank-specific requirements."
        ]
      }
    }
  }

   
    const features =[
      {
        icon:  percentageTag, 
        title: 'Offers for New Customers',
      },
      {
        icon:   coinsHands, 
        title: '3 Unique Variants',
      },
      {
        icon: coinBlog,  
        title: 'Loan of up to Rs. 50 Lakh',
      },
      {
        icon:    keycoin,  
        title: 'Tenure - 15 Years',
      },
  
      {
          icon:    groupImg,  
          title: 'No Guarantor / Collateral',
        },
       
        {
          icon:    keycoin1,  
          title: 'No Hidden Charges',
        },
    ]
  
  return (
    <>
    <div className="App">
      <div className="flex space-x-4 border-b-2 font-semibold" style={{overflowX:"auto"}}>
        {tabs.map(tab => (
         <a
         style={{textWrap: "nowrap"}}
         href={`#${tab.id}`}
         key={tab.id}
         className={`py-2 px-4 ${
           activeTab === tab.name ? 'border-b-2 border-red-500 text-red-500' : 'text-gray-500'
         }`}
         onClick={() => setActiveTab(tab.name)}
       >
         {tab.name}
       </a>
        ))}
      </div>
    </div>
    <div id="features-section">
    <Features title={"Working Capital/OverDraft Limit Features"} features={features}/>

    </div>
    <div id="features-section2">
    <FeatureUser title={"Features of Working Capital/Overdraft Limit:"} features={data?.workingCapitalOverdraftLimit?.features}/>
    <FeatureUser title={"Personal Eligibility Criteria for Working Capital/Overdraft Limit:"} features={data?.workingCapitalOverdraftLimit?.eligibilityCriteria?.personal}/>

    </div>

 
    <div id="documents-section">
    <Document loanType="Working_Capital/Overdraft_Limit" />
    </div>
    <div id="eligibility-section">
      <PageLoanApply title={"Working Capital/Overdraft Limit"}/>
      </div>
    <div id="fees-charges-section">
    <Charges loanType={"Working Capital/Overdraft Limit"}/>
    </div>
    <div id="reviews-section">
 
    <LoanPropertyReview title={"Working Capital/Overdraft Limit"} loanType={"working_capital"}/>
    </div>
    <div id="faq-section">
   
    <LoanPropertfaq  loanType={"working_capital"}/>
    </div>

   
    
      
  </>
  )
}

export default WorkingCapitalNavbar
