import React from "react";

function CarLoanEligibleRead() {
  return (
    <div className="max-w-[1260px] mx-auto p-4 text-lg mt-4">
      <h1 className="text-3xl font-semibold  mb-4">Car Loan Eligibility</h1>
      <p className="text-lg text-gray-700 mb-4">
        Car loan eligibility is about whether you can get a loan to buy a car. It depends on things like how much you earn, your credit score, and if you have other debts. Lenders use these details to decide if you can repay the loan. If you meet their criteria, you'll be eligible for the loan. If not, you might need to wait or improve your financial situation before getting a car loan.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Car Loan Eligibility Calculator</h2>
      <p className="text-gray-700 mb-4">
        Refer to the Calculator
      </p>

      <h2 className="text-2xl font-semibold mb-2">Car Loan Eligibility Criteria for Top Banks</h2>
      <p className="text-gray-700 mb-4">
        Car loan eligibility criteria vary from one bank to another, but generally include factors such as your age (usually 21 to 65 years), minimum income (often around INR 20,000 per month), and stable employment. A good credit score (usually 650 or above) is important. Some banks might require you to be a salaried employee or self-employed, while others could need you to have a certain work experience. However, you should also check with each bank directly or using their online eligibility calculators to provide precise criteria tailored to their policies.
      </p>

      <h2  className="text-2xl font-semibold mb-2">Car Loan Eligibility for Salaried Individuals/Self-Employed Individuals</h2>

      <h3  className="text-2xl font-semibold mb-2">For Salaried Individuals</h3>
      <ul className="list-disc ml-4 text-gray-700">
        <li>Individuals who are at least 21 years old at the time of application and no older than 60 at the end of the loan tenure.</li>
        <li>Individuals who have worked for at least one year, with at least one year with the current employer.</li>
        <li>Individuals with a minimum earning of Rs. 3,00,000 per year, including the income of the spouse/co-applicant.</li>
      </ul>

      <h3  className="text-2xl font-semibold mb-2">For Self Employed Individuals</h3>
      <ul className="list-disc ml-4 text-gray-700">
        <li>Individuals who are at least 21 years old at the time of application and no older than 65 at the end of the loan tenure.</li>
        <li>Those who have been in business for at least two years.</li>
        <li>Should earn at least Rs. 3,000,000 per year.</li>
      </ul>

      <h2  className="text-2xl font-semibold mb-2">Documents Required to Apply for Car Loan</h2>
      <ul className="list-disc ml-4 text-gray-700 flex flex-col gap-2">
        <li>KYC documents (Valid Photo ID Proof)</li>
        <li>PAN Card</li>
        <li>Last 2 years IT proof of income</li>
        <li>Salary Slip (last 3 months)</li>
        <li>Salary account statement (last 6 months)</li>
        <li>Signature Verification Proof</li>
      </ul>

      <h2  className="text-2xl font-semibold mb-2">EMI Calculator for Car Loan</h2>
      <p className="text-gray-700 mb-4">
        An EMI calculator is a useful tool that can help you estimate the monthly installments you will have to pay towards your used Car Loan within a specific period. By using the BluBucksEMI calculator, you can calculate your EMI beforehand, which can help you plan your finances better. Additionally, you can check your eligibility and compare different loan options using BluBucksCar Loan calculator.
      </p>

      <p className="text-gray-700 mb-4">
        Using a Car Loan EMI (Equated Monthly Installment) calculator can help you estimate your monthly loan repayment amount. Follow these steps to use a Car Loan EMI calculator effectively:
      </p>

      <ul className="list-disc ml-4 text-gray-700 flex flex-col gap-2">
        <li>Enter Loan amount, interest rate, tenure.</li>
        <li>Click Calculate.</li>
        <li>View EMI, total interest, repayment.</li>
        <li>Adjust tenure if needed.</li>
        <li>Consider extra costs.</li>
        <li>Check budget compatibility.</li>
        <li>Confirm with the lender before finalizing.</li>
      </ul>

      <h3 className="text-xl font-bold mb-2">How is Car Loan EMI Calculated?</h3>
      <p className="text-gray-700 mb-4">
        Car Loan EMI (Equated Monthly Installment) is calculated using the following formula:
      </p>

      <p className="text-gray-700 mb-4 text-lg">
        EMI = P * r * (1 + r)^n / [(1 + r)^n - 1]
      </p>
      <p className="text-gray-700 mb-4">
        Where:
      </p>

      <ul className="list-disc ml-4 text-gray-700 flex flex-col gap-2">
        <li>EMI = Equated Monthly Installment</li>
        <li>P = Loan principal amount</li>
        <li>r = Monthly interest rate (Annual interest rate divided by 12, expressed as a decimal)</li>
        <li>n = Loan tenure in months</li>
      </ul>
    </div>
  );
}

export default CarLoanEligibleRead;
