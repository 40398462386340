import { useState } from "react";

function DocumentsRequired() {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };
  const loans = {
    personal_loan_salaried: {
      tenure: "Max 4 Years",
      minimum_age: 24,
      identity_proof: ["Photograph", "Pan card", "Aadhar card"],
      proof_of_residence: ["Aadhar Card", "Utility Bill", "Rent Agreement"],
      income_proof: [
        "3 months latest salary slip",
        "2 years Form 16",
        "1 Year Bank Statement of all accounts",
      ],
      loan_proof: ["All Loan sanction Letter"],
    },
    home_loan_salaried: {
      max_tenure: "30 Years",
      minimum_age: 24,
      identity_proof: ["Photograph", "Pan card", "Aadhar card"],
      proof_of_residence: ["Aadhar Card", "Utility Bill", "Rent Agreement"],
      income_proof: [
        "3 months latest salary slip",
        "2 years Form 16",
        "1 Year Bank Statement of all accounts",
      ],
      loan_proof: ["All Loan sanction Letter"],
      property_documents: ["Property Papers"],
    },
    loan_against_property_salaried: {
      tenure: "15 Years",
      age_range: "24-65",
      identity_proof: ["Photograph", "Pan card", "Aadhar card"],
      proof_of_residence: ["Aadhar Card", "Utility Bill", "Rent Agreement"],
      income_proof: [
        "2 years Form 16",
        "1 Year Bank Statement of all accounts",
      ],
      loan_proof: ["All Loan sanction Letter"],
      property_documents: ["Property Papers"],
    },
    home_loan_self_employed: {
      tenure: "30 Years",
      identity_proof: ["Photograph", "Pan card", "Aadhar card"],
      proof_of_residence: ["Aadhar Card", "Utility Bill", "Rent Agreement"],
      income_proof: [
        "3 years ITR with financials",
        "1 Year Bank Statement of all accounts",
        "GST Certificate",
        "1 Year GST3B",
      ],
      loan_proof: ["All Loan sanction Letter", "SOA"],
      property_documents: ["Property Papers"],
      additional_documents: ["MSME certificate"],
    },
    loan_against_property_self_employed: {
      tenure: "15 Years",
      identity_proof: ["Photograph", "Pan card", "Aadhar card"],
      proof_of_residence: ["Aadhar Card", "Utility Bill", "Rent Agreement"],
      income_proof: [
        "3 years ITR with financials",
        "1 Year Bank Statement of all accounts",
        "GST Certificate",
        "1 Year GST3B",
      ],
      loan_proof: ["All Loan sanction Letter", "SOA"],
      property_documents: ["Property Papers"],
      additional_documents: ["MSME certificate"],
    },
    business_loan: {
      tenure: "Max 3 Years",
      identity_proof: ["Photograph", "Pan card", "Aadhar card"],
      proof_of_residence: ["Aadhar Card", "Utility Bill", "Rent Agreement"],
      income_proof: [
        "3 years ITR with financials",
        "1 Year Bank Statement of all accounts",
        "GST Certificate",
        "1 Year GST3B",
      ],
      loan_proof: ["All Loan sanction Letter", "SOA"],
      property_documents: ["Property Papers"],
      additional_documents: ["MSME certificate"],
    },
  };
  return (
    <div>
      <div className="max-w-[1260px] mx-auto p-4 ">
        <h2 className="text-2xl font-bold mb-4">
          Documents Required to Apply for Personal Loan
        </h2>
        <div className="border rounded-md p-4">
          <ul className="flex flex-col gap-2 text-lg">
            <li className="mb-2">
              <span className="font-bold">Identity Proof</span> - Photograph ,
              Pan card & Adhar card
            </li>
            <li className="mb-2">
              <span className="font-bold">
                Proof of Residence or Address Proof
              </span>
              - Adhar Card /Utility Bill /Rent Agreement
            </li>
            <li className="mb-2">
              <span className="font-bold">Income Proof</span> - 3 months latest
              salary slip + 2 years Form 16 + 1 Year Bank Statement of all
              accounts
            </li>
            <li className="mb-2">
              <span className="font-bold">Loan Proof</span> - All Loan sanction
              Letter
            </li>
            <li className="mb-2">
              <span className="font-bold">Minimum Age</span> - 24
            </li>
          </ul>
        </div>

        <h2 className="text-2xl font-bold mt-8 mb-4">
          EMI Calculator for Personal Loan
        </h2>
        <div className="border rounded-md p-4 flex flex-col gap-2 text-lg">
          <p>
            An EMI calculator is a useful tool that can help you estimate the
            monthly installments you will have to pay towards your personal loan
            within a specific period. By using the Ruloans{" "}
            <a href="#" className="underline text-blue-500">
              Personal Loan EMI calculator
            </a>
            , you can calculate your EMI beforehand, which can help you plan
            your finances better. Additionally, you can check your eligibility
            and compare different loan options using BluBucksPersonal Loan
            calculator.
          </p>
          <button
            className="bg-red-500 hover:bg-red-700 w-[200px] rounded-md text-white font-bold py-4 px-4 focus:outline-none focus:shadow-outline"
            onClick={toggleDetails}
          >
            {showDetails ? "Hide Details" : "Show More Details"}
          </button>
          {showDetails && <div className="mt-4"></div>}
        </div>
      </div>
    </div>
  );
}

export default DocumentsRequired;
