import React from 'react';
import boy from '../../creaditcardComponents/cardTypeImages/boy1.png';
import girl from '../../creaditcardComponents/cardTypeImages/girl1.png';
import quotes from '../../creaditcardComponents/cardTypeImages/quotes.png';

const PersonalLoanReview = () => {
    const reviews = [
        {
            name: "Shreya Mehta",
            rating: 5,
            review: "BluBucks assisted me in getting a personal loan during an urgent financial need. The process was quick, and the interest rates were reasonable. Their team guided me throughout the process and made everything stress-free.",
            images: girl,
            quote: quotes,
        },
        {
            name: "Amit Verma",
            rating: 4,
            review: "I applied for a personal loan through BluBucks to cover medical expenses, and I was impressed with how fast the loan was approved. The entire process was seamless, and I received the loan amount in just a few days.",
            images: boy,
            quote: quotes,
        },
        {
            name: "Priya Desai",
            rating: 4,
            review: "BluBucks helped me secure a personal loan for my wedding expenses. Their customer service was excellent, and they ensured I got a loan with a competitive interest rate. Highly recommend their services!",
            images: girl,
            quote: quotes,
        },
        {
            name: "Rohit Sharma",
            rating: 5,
            review: "The personal loan I received through BluBucks helped me consolidate my debts. The process was fast, and the repayment terms were flexible, making it easy for me to manage my finances. Great experience overall!",
            images: boy,
            quote: quotes,
        },
    ];
    
    return (
        <div className="max-w-screen-xl mx-auto rounded-lg p-6 flex flex-col bg-white">
            <h2 className="text-3xl font-bold mb-8">Personal Loan Reviews</h2>
            <div className='grid lg:grid-cols-2 gap-8'>
                {reviews.map((review, index) => (
                    <div key={index} className='bg-[#FAFAFA] border border-gray-200 p-6 rounded-lg shadow-md relative'>
                        <div className='flex items-start gap-4 mb-4'>
                            <img src={review.quote} alt="Quote Icon" className="w-10 " />
                            <p className='text-yellow-400 text-3xl ml-6'>
                                {'★'.repeat(review.rating)}{'☆'.repeat(5 - review.rating)}
                            </p>
                        </div>



                        <div className='w-[95%] flex justify-end ml-6 border-l-4 border-l-[#4376BA]'>
                            <p className='text-gray-800 mb-6 text-lg ml-2 w-[80%]'>{review.review}</p>
                        </div>



                        <div className='flex items-center gap-4 mt-4'>
                            <img className='w-16 h-16 rounded-full border border-gray-300 p-1' src={review.images} alt={review.name} />
                            <p className='font-semibold text-xl flex items-center'>
                                {review.name}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PersonalLoanReview;
