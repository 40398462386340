import { useState } from 'react';

function FeatureUser({title,features}) {
  
  return (
    <div className="max-w-[1260px] mx-auto mt-4">
      <h2 className="text-4xl font-semibold  mb-4">
        {title}
      </h2>
      <div className="flex justify-center">
         
      </div>
      
        <ul className="list-disc pl-6 mt-4 flex flex-col gap-2 text-lg">
        {features?.map((res,index)=>(
          <li>
          <span className="font-bold">{res.split(" – ")[0]}</span> - {res.split(" – ")[1]}
        </li>
        ))}
        
        </ul>
      
    </div>
  );
}

export default FeatureUser;