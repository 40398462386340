import React from 'react';
import boy from '../../creaditcardComponents/cardTypeImages/boy1.png';
import girl from '../../creaditcardComponents/cardTypeImages/girl1.png';
import quotes from '../../creaditcardComponents/cardTypeImages/quotes.png';

const HomeLoanReviews = () => {
    const reviews = [
        {
            name: "Anjali Sharma",
            rating: 5,
            review: "I had an amazing experience with BluBucks. Their loan approval process was fast and hassle-free. The customer service was always available to answer my questions and guide me through the application process. I would highly recommend BluBucks for anyone looking for a reliable home loan provider.",
            images: girl,
            quote: quotes,
        },
        {
            name: "Rohan Gupta",
            rating: 4,
            review: "The loan application process was smooth and transparent. I received a competitive interest rate for my home loan, and the EMI options provided were flexible. Overall, I am very satisfied with the services provided by BluBucks.",
            images: boy,
            quote: quotes,
        },
        {
            name: "Priya Verma",
            rating: 5,
            review: "I was initially skeptical about applying for a home loan online, but BluBucks made it so easy. The EMI calculator helped me plan my budget effectively, and I received approval faster than I expected. Great experience!",
            images: girl,
            quote: quotes,
        },
        {
            name: "Amit Singh",
            rating: 4,
            review: "BluBucks offers one of the best home loan services. The application process was simple, and I got a great deal on interest rates. However, I wish the pre-closure terms were a bit more flexible. Overall, it's a good experience.",
            images: boy,
            quote: quotes,
        },
    ];

    return (
        <div className="max-w-screen-xl mx-auto rounded-lg p-6 flex flex-col bg-white">
            <h2 className="text-3xl font-bold mb-8">Personal Loan Reviews</h2>
            <div className='grid lg:grid-cols-2 gap-8'>
                {reviews.map((review, index) => (
                    <div key={index} className='bg-[#FAFAFA] border border-gray-200 p-6 rounded-lg shadow-md relative'>
                        <div className='flex items-start gap-4 mb-4'>
                            <img src={review.quote} alt="Quote Icon" className="w-10 " />
                            <p className='text-yellow-400 text-3xl ml-6'>
                                {'★'.repeat(review.rating)}{'☆'.repeat(5 - review.rating)}
                            </p>
                        </div>

                        <div className='w-[95%] flex justify-end ml-6 border-l-4 border-l-[#4376BA]'>
                            <p className='text-gray-800 mb-6 text-lg ml-2 w-[80%]'>{review.review}</p>
                        </div>

                        <div className='flex items-center gap-4 mt-4'>
                            <img className='w-16 h-16 rounded-full border border-gray-300 p-1' src={review.images} alt={review.name} />
                            <p className='font-semibold text-xl flex items-center'>
                                {review.name}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomeLoanReviews;
