import React from 'react';
import boy from '../../creaditcardComponents/cardTypeImages/boy1.png';
import girl from '../../creaditcardComponents/cardTypeImages/girl1.png';
import quotes from '../../creaditcardComponents/cardTypeImages/quotes.png';

const LoanPropertyReview = ({title,loanType}) => {
    const reviews = {
        
        property_loan:[
        {
            name: "Shreya Mehta",
            rating: 4,
            review: "I was going through a financial crunch in my business, and BluBucks suggested applying for a Loan Against Property. Thanks to their advice, I could secure the loan and use it to stabilize my business. Their service was prompt and professional.",
            images: girl,
            quote: quotes,
        },
        {
            name: "Amit Malhotra",
            rating: 5,
            review: "I applied for a Loan Against Property through BluBucks, and the entire process was smooth and transparent. The loan helped me consolidate my debts and use the property equity for better financial management. The customer service was outstanding!",
            images: boy,
            quote: quotes,
        },
        {
            name: "Priya Desai",
            rating: 4,
            review: "I needed funds to expand my business, and BluBucks suggested a Loan Against Property. Their team was extremely helpful in getting my loan approved quickly. I’m glad I took their advice, as it helped me use my property’s value for a productive investment.",
            images: girl,
            quote: quotes,
        },
        {
            name: "Rohit Sharma",
            rating: 4,
            review: "BluBucks helped me get a Loan Against Property from HDFC bank with minimal hassle. The team was very responsive and helped me understand the process, including the interest rates and repayment terms. It was a great solution for my financial needs!",
            images: boy,
            quote: quotes,
        },
        {
            name: "Neha Verma",
            rating: 5,
            review: "I opted for a Loan Against Property through BluBucks to fund my children’s education. Their team provided excellent advice on leveraging my property to secure a low-interest loan. The process was fast, and the funds were disbursed within days!",
            images: girl,
            quote: quotes,
        },
    ],
    working_capital: [
        {
            name: "Shreya Mehta",
            rating: 4,
            review: "I was facing cash flow challenges in my business, and BluBucks recommended a Working Capital Overdraft Limit. This solution allowed me to access funds whenever needed and helped me manage fluctuations in my cash flow smoothly. Their prompt service made a huge difference!",
            images: girl,
            quote: quotes,
        },
        {
            name: "Amit Malhotra",
            rating: 5,
            review: "I applied for a Working Capital Overdraft Limit through BluBucks to support my business expansion. The process was seamless, and I could use the funds as required without a fixed repayment schedule. The flexibility and customer service were top-notch!",
            images: boy,
            quote: quotes,
        },
        {
            name: "Priya Desai",
            rating: 4,
            review: "BluBucks helped me secure a Working Capital Overdraft Limit that provided immediate access to funds during a slow season in my business. The revolving facility allowed me to withdraw and repay funds multiple times, keeping my operations running smoothly.",
            images: girl,
            quote: quotes,
        },
        {
            name: "Rohit Sharma",
            rating: 4,
            review: "Thanks to BluBucks, I got a Working Capital Overdraft Limit with minimal paperwork. Their team explained all the charges clearly and helped me understand the benefits of using an overdraft facility for better financial management. It was a smart solution for my business needs!",
            images: boy,
            quote: quotes,
        },
        {
            name: "Neha Verma",
            rating: 5,
            review: "I opted for a Working Capital Overdraft Limit from BluBucks to support my business during a cash crunch. Their team provided excellent advice on managing liquidity, and I appreciated the quick access to funds whenever required. The whole experience was hassle-free!",
            images: girl,
            quote: quotes,
        },
        
        {
            name: "Vikram Patel",
            rating: 5,
            review: "BluBucks suggested a Working Capital Overdraft Limit when I needed funds to purchase additional inventory. The flexibility to access funds as needed without a rigid repayment structure was exactly what my business required. I’m grateful for their expert guidance and quick service.",
            images: boy,
            quote: quotes,
        },
    ]}
    const loanReview = reviews[loanType];
    return (
        <div className="max-w-screen-xl mx-auto rounded-lg p-6 flex flex-col mt-5 bg-white">
            <h2 className="text-3xl font-bold mb-8">{title} Reviews</h2>
            <div className='grid lg:grid-cols-2 gap-8'>
                {loanReview.map((review, index) => (
                    <div key={index} className='bg-[#FAFAFA] border border-gray-200 p-6 rounded-lg shadow-md relative'>
                        <div className='flex items-start gap-4 mb-4'>
                            <img src={review.quote} alt="Quote Icon" className="w-10 " />
                            <p className='text-yellow-400 text-3xl ml-6'>
                                {'★'.repeat(review.rating)}{'☆'.repeat(5 - review.rating)}
                            </p>
                        </div>



                        <div className='w-[95%] flex justify-end ml-6 border-l-4 border-l-[#4376BA]'>
                            <p className='text-gray-800 mb-6 text-lg ml-2 w-[80%]'>{review.review}</p>
                        </div>



                        <div className='flex items-center gap-4 mt-4'>
                            <img className='w-16 h-16 rounded-full border border-gray-300 p-1' src={review.images} alt={review.name} />
                            <p className='font-semibold text-xl flex items-center'>
                                {review.name}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LoanPropertyReview;
