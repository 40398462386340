import React from 'react';
import InsuranceCard from '../Insurance/Insurance';
import EMICalculator from '../EmiCalculator/EmiCalculator';
import Aboutrelon from '../../components/AboutReloan/Aboutreloan';
import TimelineSection from '../AboutReloan/Timeline';
import FAQ from '../Faqcomponent/FAQ';
import CrouselComponent from '../CarouselHero/CrouselHero';
import New from '../News/New';
import { Link } from 'react-router-dom';

const loanData = [
 
  {
    title: 'Personal Loan',
    description: 'Transform your dreams into reality with our wide range of personal loan options.',
    image: '/assest/Creditcard/personal-loan.png', 
    link: '/personal-loan' 
  },
  {
    title: 'Home Loan',
    description: 'Your dream home is within reach – discover our variety of home loan options.',
    image: '/assest/Creditcard/home-loan.png', 
    link: '/home-loan' 
  },
  {
    title: 'Business Loan',
    description: 'Give your business the financial boost it needs with our flexible loan options.',
    image: '/assest/Creditcard/Business-loan.png', 
    link: '/business-loan' 
  },
  {
    title: 'Loan against Property',
    description: 'Unlock your property\'s potential to achieve your goals with our customized Loan Against ',
    image: '/assest/Creditcard/loan-against-property.png', 
    link: '/business-against-property' 
  },
  {
    title: 'Working Capital/Overdraft Limit',
    description: 'Unlock the power of seamless cash flow with our flexible working capital and overdraft solutions.',
    image: '/assest/Creditcard/capital.png', 
    link: '/Working-Capital-OverDraft-limit' 
  },

];

function CreditCard() {
  return (
    <>
      <CrouselComponent/>
      <div className='bg-[#E0F3F7]'>
      <div className="p-4 md:p-8 lg:p-12 max-w-[1260px] mx-auto">
        <h1 className="text-3xl md:text-5xl font-bold mb-8 text-gray-900 text-center">Loans</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {loanData.map((loan, index) => (
            <div 
              key={index} 
              className="bg-[#E0F3F7] bg-gradient-to-r from-[#E0F3F7] to-white shadow-xl rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl relative"
            >
              <div className="p-6">
                <img 
                  src={loan.image} 
                  alt={loan.title} 
                  className="w-20 h-20 object-contain mb-4 mx-auto"
                />
                <h2 className="text-2xl font-semibold mb-2 text-gray-800 text-center">{loan.title}</h2>
                <p className="text-gray-600 text-center mb-4">{loan.description}</p>
                <a 
                  href={loan.link} 
                  className="block bg-[#0F63AC] text-white text-center py-2 px-4 rounded-lg hover:bg-red-600 transition duration-200 mx-auto max-w-[200px]"
                  style={{ width: 'fit-content' }}
                >
                  Check Eligibility
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>
      <InsuranceCard/>
      <EMICalculator/>
      <Aboutrelon/>
      {/* <TimelineSection/> */}
      <New/>
      <FAQ/>
      <div className="fixed right-6 bottom-2 z-50">
    <a href="https://wa.me/8505965050" className="bg-green-500  text-white rounded-circle  shadow flex align-items-center justify-center" style={{width:"50px",height:"50px"}}>
        <i className="fa-brands fa-whatsapp fs-2"></i>
        </a>
</div>
<div className="fixed left-6 bottom-2 z-50">
<a href="https://www.facebook.com/people/Blubucks-Asset-Pvt-Limited/61561559884989/" className="bg-blue-500  text-white rounded-circle  shadow flex align-items-center justify-center" style={{width:"50px",height:"50px"}}>
        <i class="fa-brands fa-facebook fs-2"></i>
        </a>
</div>
    </>
  );
}

export default CreditCard;
