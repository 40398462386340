import React from 'react'
import LoansRoute from './routes/LoansRoute'

function App() {
  return (
    <>  
     <LoansRoute/>
    </>

  )
}

export default App