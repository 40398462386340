import { useState } from "react";
import { Link } from "react-router-dom";
import bluebuks from '../creaditcardComponents/cardTypeImages/Blubucks.png'
import RequestaCallback from "../components/EnquiryForm/RequestaCallback";
export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
const [showmodal,setShowModal] = useState(false)
  return (
    <nav className="bg-white shadow-md">
      <RequestaCallback setshowModal={setShowModal} showModal={showmodal}/>
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center space-x-4">
         <Link to='/' style={{    maxWidth: "210px",
    height: "36px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center"}}>
         <img src= {bluebuks} alt="Logo" style={{width:"100%",objectFit:"contain"}}/>
         </Link>
          <div className="hidden lg:flex space-x-4">
            <Link to='/about-us' className="text-lg font-semibold">
              About Us
            </Link>
            <li class="nav-item dropdown list-none">
          <a className="nav-link dropdown-toggle text-lg font-semibold flex items-center" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Loans
          </a>
          <ul class="dropdown-menu">
            <li> <Link  to='/personal-loan' className="block px-4 py-2 hover:bg-gray-100 text-nowrap">
                    Personal Loan
                  </Link></li>
            <li><Link to='/home-loan' className="block px-4 py-2 hover:bg-gray-100 text-nowrap">
                    Home Loan
                   </Link></li>
            <li>  <Link to='/business-loan' className="block px-4 py-2 hover:bg-gray-100 text-nowrap">
                    Business Loan
                  </Link></li>
                  <li> <Link to='/business-against-property' className="block px-4 py-2 hover:bg-gray-100 text-nowrap">
                    Loan Against Property
                   </Link></li>
                   
                    <li> <Link to='/Working-Capital-OverDraft-limit' className="block px-4 py-2 hover:bg-gray-100 text-nowrap">
                    Working Capital /OverDraft limit
                   </Link></li>
          </ul>
        </li>
           
            <Link  to='/contact-us' className="text-lg font-semibold">
              Contact Us
            </Link>

            <Link to="/become-a-partner" className="text-lg font-semibold">
              Become a Partner
            </Link>
            <a href="#" className="text-lg font-semibold">
              <span className="bg-[#0F63AC] text-white px-2 py-1 rounded">Coming Soon</span> Check Cibil Score
            </a>
          </div>
        </div>
        <div className="hidden lg:flex lg:items-center">
          <a href="#" className="text-lg text-white font-semibold bg-[#0F63AC] px-4 py-2 rounded-md shadow-xl" onClick={()=>setShowModal(true)}>
            DOWNLOAD APP
          </a>
        </div>
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="text-2xl">
            {isMenuOpen ? "✖" : "☰"}
          </button>
        </div>
      </div>

      {isMenuOpen && (
        <div className="lg:hidden flex flex-col space-y-2 p-4">
         <Link to='/about-us' className="text-lg font-semibold">
              About Us
            </Link>
          <li class="nav-item dropdown list-none">
          <a className="nav-link dropdown-toggle text-lg font-semibold flex items-center" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Loans
          </a>
          <ul class="dropdown-menu">
            <li> <Link  to='/personal-loan' className="block px-4 py-2 hover:bg-gray-100">
                    Personal Loan
                  </Link></li>
            <li><Link to='/home-loan' className="block px-4 py-2 hover:bg-gray-100">
                    Home Loan
                   </Link></li>
            <li>  <Link to='/business-loan' className="block px-4 py-2 hover:bg-gray-100">
                    Business Loan
                  </Link></li>
            <li> <Link to='/business-against-property' className="block px-4 py-2 hover:bg-gray-100">
                    Loan Against Property
                   </Link></li>
          </ul>
        </li>
        
        <Link  to='/contact-us' className="text-lg font-semibold">
              Contact Us
            </Link>

            <Link to="/become-a-partner" className="text-lg font-semibold">
              Become a Partner
            </Link>
            <a href="#" className="text-lg font-semibold">
              <span className="bg-[#0F63AC] text-white px-2 py-1 rounded">Coming Soon</span> Check Cibil Score
            </a>
          <a href="#" className="text-lg text-white font-semibold bg-[#0F63AC] px-4 py-2 rounded-md" onClick={()=>setShowModal(true)}>
           DOWNLOAD APP
          </a>
        </div>
      )}
    </nav>
  );
}
