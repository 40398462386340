import React, { useEffect, useState } from "react";
import "./Contact.css"; // Ensure to add the styles below in ContactUs.css
import contact from "../../Images/contact.jpg";
import FormValidation from "../../Utils/FormValidation";
import FormInputChange from "../../Utils/FormInputChange";
import { NavLink } from "react-router-dom";

const Contact = () => {
  const [errors, setErrors] = useState({});
  const [buttondisable, setbuttondisable] = useState({
    disable: false,
    text: "Submit",
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile_no: "",
    message: "",
  });

  const handleInputChange = (e) => {
    FormInputChange(e, formData, setFormData, errors, setErrors);
  };

  const errorTexts = {
    mobile_no: "Mobile number is required*",
    agree: "You must agree to the terms of service and privacy policy",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (FormValidation(formData, errorTexts, setErrors)) {
      setbuttondisable({
        ...buttondisable,
        disable: true,
        text: "Please wait...",
      });

      try {
        const response = await fetch("https://blubucks.in/api/enquiries/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          const result = await response.json();
          setbuttondisable({
            ...buttondisable,
            disable: false,
            text: "Submit",
          });
          // Optionally reset the form
          setFormData({
            name: "",
            email: "",
            mobile_no: "",
            message: "",
          });
        } else {
          const errorData = await response.json();
          setErrors(errorData.errors || {});
          setbuttondisable({
            ...buttondisable,
            disable: false,
            text: "Submit",
          });
        }
      } catch (error) {
        console.error("There was an error!", error);
        setbuttondisable({
          ...buttondisable,
          disable: false,
          text: "Submit",
        });
      }
    }
  };
  useEffect(()=>{

    scrollToTop()
  },[])
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'instant' });
    };
  
  return (
    <div className="contact-us-main">
      <div className="contact-us-section">
        <div className="message-form">
          <h3>
            Contact BluBucks{" "}
            <span role="img" aria-label="rocket">
              🚀
            </span>
          </h3>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <div class="mb-3">
              <label for="validationServer01" class="form-label">
                Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className={`form-control ${errors.name && "is-invalid"}`}
                aria-describedby="validationname"
                placeholder="Enter your Name*"
                value={formData.name}
                onChange={handleInputChange}
              />
              <div id="validationname" className="invalid-feedback">
                {errors.name}
              </div>
            </div>
            <div class="mb-3">
              <label for="validationServer01" class="form-label">
                Email ID<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="email"
                id="email"
                className={`form-control ${errors.email && "is-invalid"}`}
                aria-describedby="validationemail"
                placeholder="Enter your Email ID"
                value={formData.email}
                onChange={handleInputChange}
              />
              <div id="validationemail" className="invalid-feedback">
                {errors.email}
              </div>
            </div>

            <div class="mb-3">
              <label for="mobile" class="form-label">
                Mobile Number<span className="text-danger">*</span>
              </label>
              <div class="input-group has-validation">
                <input
                  type="text"
                  name="mobile_no"
                  placeholder="Enter your mobile number"
                  className={`form-control rounded-end ${
                    errors.mobile_no && "is-invalid"
                  }`}
                  id="mobile"
                  value={formData.mobile_no}
                  onChange={handleInputChange}
                  aria-describedby="mobile"
                />
                <div id="mobile" class="invalid-feedback">
                  {errors.mobile_no}
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="graduation" class="form-label">
                Message<span className="text-danger">*</span>
              </label>
              <textarea
                type="text"
                name="message"
                className={`form-control ${errors.message && "is-invalid"}`}
                id="message"
                placeholder="Enter your Message"
                value={formData.message}
                aria-describedby="validationgraduation"
                onChange={handleInputChange}
                rows={5}
              />
              <div id="validationgraduation" className="invalid-feedback">
                {errors.message}
              </div>
            </div>

            <button
              className="rounded bg-[#0F63AC] mb-2 p-2 ps-3 pe-3 border-0 register-btn"
              type="submit"
              disabled={buttondisable.disable}
            >
              {buttondisable.text}
            </button>
          </form>
        </div>
        <div className="contact-info">
          <img src={contact} alt="blubucks - contact us" />
        </div>
      </div>

      <div className="map-container">
        <h3>Our Location</h3>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1545.822322247323!2d77.0795102!3d28.6285647!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d04bf22cc0c5d%3A0xda85aea486230349!2sStationary%20Point!5e1!3m2!1sen!2sin!4v1726948751679!5m2!1sen!2sin"
          width="600"
          height="450"
          style={{border:0}}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
