import React, { useState } from 'react';
import RequestaCallback from '../../components/EnquiryForm/RequestaCallback';

const HomeLoanEligibility = () => {
  const [showmodal,setShowModal] = useState(false)

  return (
    <>
      <RequestaCallback title="Apply for Home Loan" setshowModal={setShowModal} showModal={showmodal}/>

      <div className="max-w-[1260px] mx-auto  shadow-md rounded-lg  p-4  bg-[#EEF4FF] border border-[#8A98BF] mt-4">
        <h2 className="text-3xl  font-semibold mb-2">Home Loan Eligibility and Documents</h2>
        <div className='flex justify-between mt-8 flex-wrap '>
          <p className="text-gray-700 mb-4 text-xl">
            Read on to know the criteria required to apply for our Home Loan.
          </p>
          <button onClick={()=>setShowModal(true)} className="bg-red-500 text-white py-4 px-16 rounded text-xl hover:bg-red-600 font-bold">
            Apply
          </button>
        </div>
      </div>

      <div className="max-w-[1260px] mx-auto p-4 mt-4">
        <h1 className="text-4xl font-semibold mb-4">Home Loan Eligibility</h1>
        <p className="mb-4 text-lg">
          The basic <a href="#" className="underline">home loan eligibility</a> criteria are as follows:
        </p>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Age:</h2>
          <ul className="list-disc pl-6 flex flex-col gap-4 text-lg">
            <li>You must be above 21 years of age at the time of the commencing of your loan, and up to 65 years or less at loan maturity</li>
            <li>Salaried Person - 21 years to 58 years</li>
            <li>Self-Employed Person - 25 years to 65 years</li>
          </ul>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Income:</h2>
          <ul className="list-disc pl-6 text-lg flex flex-col gap-4">
            <li>Salaried Person - Minimum Rs. 10,000 per month</li>
            <li>Self-Employed Person - Minimum Rs. 2,00,000 per year</li>
          </ul>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Employment:</h2>
          <ul className="list-disc pl-6 flex flex-col gap-4 text-lg">
            <li>Salaried Person - Minimum 2 to 3 years of Experience in MNC, or a Private or Public Limited Company</li>
            <li>Self-Employed Person - Minimum 3 years of Experience in the current field</li>
          </ul>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Credit Score:</h2>
          <ul className="list-disc pl-6 text-lg flex flex-col gap-4">
            <li>A credit score of 750 is considered a good score for approval.</li>
          </ul>
        </div>
      </div>

    </>

  );
};

export default HomeLoanEligibility;
