import React, { useEffect } from 'react';
import styled from 'styled-components';

const termsData = {
  terms: {
    title: "Terms and Conditions",
    introduction:
      "Welcome to the Bluebucks Asset Pvt Ltd Website. These Terms and Conditions (the \"Terms\") govern your access to and use of the website located at www.bluebucks.com (the \"Site\") and any services provided by Bluebucks Asset Pvt Ltd (\"Bluebucks,\" \"we,\" \"us,\" or \"our\"). By accessing or using the Site, you agree to comply with these Terms. If you do not agree with any part of these Terms, you must not use the Site.",
    sections: [
      {
        title: "1. Description of Services",
        content:
          "Bluebucks provides financial services, including but not limited to personal loans, home loans, property loans, and business funding (the \"Services\"). You are responsible for obtaining access to the Site, which may involve third-party fees, such as Internet service provider charges.",
      },
      {
        title: "2. Acceptance of Terms",
        content:
          "By using the Site, you confirm that you are of legal age to enter into a binding agreement and that you accept these Terms and the linked Privacy Policy. You may not use the Site if you do not accept these Terms.",
      },
      {
        title: "3. License and Site Access",
        content:
          "Bluebucks grants you a limited, non-exclusive, non-transferable license to access the Site for personal use. You may not download, modify, or distribute any content without our express written consent.",
      },
      {
        title: "4. User Accounts",
        content:
          "You may be required to create an account to use certain features of the Site. You agree to provide accurate, current, and complete information and to keep your account information up to date. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.",
      },
      {
        title: "5. Submitted Content",
        content:
          "You retain ownership of any content you submit to the Site. However, by submitting content, you grant Bluebucks a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, and distribute that content in connection with our Services.",
      },
      {
        title: "6. Disclaimer of Liability",
        content:
          "Bluebucks does not guarantee the accuracy or completeness of the information provided on the Site. We are not liable for any direct, indirect, incidental, or consequential damages arising from your use of the Site or Services.",
      },
      {
        title: "7. Indemnification",
        content:
          "You agree to indemnify and hold harmless Bluebucks, its affiliates, officers, employees, and agents from any claims, damages, losses, or expenses arising out of your violation of these Terms or your use of the Site.",
      },
      {
        title: "8. Electronic Communications",
        content:
          "By using the Site, you consent to receive electronic communications from Bluebucks. We may communicate with you via email or by posting notices on the Site.",
      },
      {
        title: "9. Third-Party Links",
        content:
          "The Site may contain links to third-party websites. Bluebucks does not endorse or assume any responsibility for the content or practices of these websites.",
      },
      {
        title: "10. Governing Law",
        content:
          "These Terms shall be governed by and construed in accordance with the laws of India. Any disputes arising from these Terms shall be subject to the exclusive jurisdiction of the courts in Mumbai.",
      },
      {
        title: "11. Changes to Terms",
        content:
          "Bluebucks reserves the right to modify these Terms at any time. We will notify you of any changes by posting the updated Terms on the Site. Your continued use of the Site after any changes signifies your acceptance of the revised Terms.",
      },
      {
        title: "12. Contact Information",
        content:
          "If you have any questions about these Terms, please contact us at support@bluebucks.com.",
      },
    ],
  },
};

const Container = styled.div`
  padding: 20px;
//   max-width: 800px;
   margin: 5%;
  padding: 20px;
    background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
  font-size: clamp(20px, 4vw, 40px);
  font-weight: 700;
`;

const Section = styled.div`
  margin-bottom: 20px;
    margin: 20px 0;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h2`
  font-size: clamp(10px, 4vw, 25px);
  margin-bottom: 10px;
    color: red;
`;

const SectionContent = styled.p`
 font-size: 16px;
  margin: 10px 0;
`;

const TermsAndConditions = () => {
  const { title, introduction, sections } = termsData.terms;
    useEffect(()=>{
  
      scrollToTop()
    },[])
    
      const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
      };
  return (
    <Container>
      <Title>{title}</Title>
      <SectionContent>{introduction}</SectionContent>
      {sections.map((section, index) => (
        <Section key={index}>
          <SectionTitle>{section.title}</SectionTitle>
          <SectionContent>{section.content}</SectionContent>
        </Section>
      ))}
    </Container>
  );
};

export default TermsAndConditions;
