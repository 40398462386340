import React from 'react';
import Document from '../personalLoan/Document';

const BussinessLoan = () => {
  return (
    <div className="font-sans max-w-[1260px] mx-auto">
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Eligibility Criteria & Eligible Entities</h1>
        <div className="bg-white rounded-md shadow-md p-4 mb-6">
          <dl className="grid grid-cols-1 gap-4 text-lg">
            <div>
              <dt className="text-gray-600 font-medium">Business Type</dt>
              <dd>Most lenders provide loans to various types of businesses, including sole proprietorships, partnerships, limited liability companies (LLCs), corporations, and more.</dd>
            </div>
            <div>
              <dt className="text-gray-600 font-medium">Credit Score</dt>
              <dd>A good credit score is often required to qualify for a business loan. Lenders use your credit history to assess your ability to repay the loan.</dd>
            </div>
            <div>
              <dt className="text-gray-600 font-medium">Business Age</dt>
              <dd>Some lenders require a minimum operational history for your business, usually ranging from a few months to a year or more.</dd>
            </div>
            <div>
              <dt className="text-gray-600 font-medium">Annual Revenue</dt>
              <dd>Lenders may have a minimum annual revenue requirement to ensure your business has a stable income.</dd>
            </div>
            <div>
              <dt className="text-gray-600 font-medium">Collateral</dt>
              <dd>Secured loans might require assets as collateral to secure the loan. Collateral can be real estate, equipment, inventory, or other valuable assets.</dd>
            </div>
            <div>
              <dt className="text-gray-600 font-medium">Cash Flow</dt>
              <dd>Lenders often evaluate your business's cash flow to determine your ability to repay the loan.</dd>
            </div>
            <div>
              <dt className="text-gray-600 font-medium">Age Criteria</dt>
              <dd>Min. 21 years at the time of loan application & Max. 65 years at the time of loan maturity.</dd>
            </div>
            <div>
              <dt className="text-gray-600 font-medium">Eligible Entities</dt>
              <dd>Individuals, MSMEs, Sole Proprietorships, Partnership Firms, Public and Private Limited Companies, Limited Liability Partnerships, retailers, traders, manufacturers, and other non-farm income-generating business entities engaged only in the services, trading, and manufacturing sectors</dd>
            </div>
            <div>
              <dt className="text-gray-600 font-medium">Business Vintage</dt>
              <dd>Min. 1 year or above</dd>
            </div>
            <div>
              <dt className="text-gray-600 font-medium">Business Experience</dt>
              <dd>Min. 1 year, business location to remain same</dd>
            </div>
            <div>
              <dt className="text-gray-600 font-medium">Annual Turnover</dt>
              <dd>Shall be defined by the Bank NBFC</dd>
            </div>
            <div>
              <dt className="text-gray-600 font-medium">Credit Score</dt>
              <dd>700 or above (Preferred by most private and public sector banks)</dd>
            </div>
            <div>
              <dt className="text-gray-600 font-medium">Nationality</dt>
              <dd>Indian citizens</dd>
            </div>
            <div>
              <dt className="text-gray-600 font-medium">Additional Criteria</dt>
              <dd>Applicants must own either a residence, office, shop, or Godown.</dd>
            </div>
          </dl>
        </div>

        <Document loanType="business_loan" />

        <h2 className="text-2xl font-bold text-gray-800 mb-4">How to use Business Loan EMI Calculator</h2>
        <div className="bg-white rounded-md shadow-md p-4 mb-6">
          <p className='text-lg'>Using a business loan EMI (Equated Monthly Installment) calculator can help you estimate your monthly loan repayment amount.</p>
          <p className='text-lg'>Follow these steps to use a business loan EMI calculator effectively:</p>
          <ul className="list-disc pl-6 flex flex-col text-lg gap-2">
            <li>Enter loan amount, interest rate, tenure.</li>
            <li>Click Calculate.</li>
            <li>View EMI, total interest, repayment.</li>
            <li>Adjust tenure if needed.</li>
            <li>Consider extra costs.</li>
            <li>Check budget compatibility.</li>
            <li>Confirm with the lender before finalizing.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BussinessLoan;
